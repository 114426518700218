import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MainDispatcher } from 'src/app/core/dispatchers/main.dispatcher';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss']
})
export class OrderDetailComponent {

  couponForm: FormGroup;
  couponError: string | null;
  items: any[] = [];
  cart: any;
  settings: any;
  prePaymentSelected: any;
  shipment: any;
  shipments: any[] = [];
  shipmentSelected: string = '';
  couponApplied: any;
  percentageDiscount: number = 0;
  realDiscount: number = 0;

  isLoading: boolean = false;
  isLoadingCoupon: boolean = false;

  constructor(
    private fb: FormBuilder,
    private mainDispatcher: MainDispatcher
  ) {
    this.listenSettings();
    this.listenCart();
    this.listenShipment();
    this.listenCoupon();
    this.listenPayment();
    this.startForm();
  }

  changeItemQuantity(item: any, newQuantity: number, index: number) {
    this.isLoading = true;
    this.mainDispatcher.changeItemQuantity(item, newQuantity);
  }

  applyCoupon() {
    this.isLoadingCoupon = true;
    this.mainDispatcher.applyCoupon(this.couponForm.value);
  }

  get itemsValue(): number {
    return this.items.reduce(
      (total, item) =>
        total +((
          item.idKit ? item.precoFinal : item.precoVenda
        )*item.quantidade),
      0);
  }

  get discountValue(): number {
    return this.percentageDiscount > 0 ?
        this.itemsValue * (this.percentageDiscount/100) :
      this.realDiscount > 0 ?
        this.realDiscount : 0;
  }
  get discountPercentageLabel(): string {
    return this.percentageDiscount > 0 ?
        `${this.percentageDiscount}%` :
      this.realDiscount > 0 ?
      `${((this.realDiscount*100) / this.itemsValue).toFixed(0)}%` : '';
  }
  get partialValue(): number { return this.itemsValue - this.discountValue; }
  get shippingValue(): number { return !this.shipment || this.shipment.freteGratis || this.freeShipment ? 0 : this.shipment.valorFrete; }
  get freeShipment(): boolean { return this.couponApplied?.aplicarFreteGratis || this.shipment?.freteGratis || this.shipment?.valorFrete === 0 ? true : false; }
  get totalValue(): number { return this.partialValue + this.shippingValue; }
  get coupon(): AbstractControl | null { return this.couponForm.get('coupon'); }

  private startForm() {
    this.couponForm = this.fb.group({
      coupon: [null, Validators.required],
    });
  }

  private listenSettings() {
    this.mainDispatcher.listenerSettings()
    .subscribe({
      next: this.treatLoadSettingsSuccesfull
    });
  }

  private treatLoadSettingsSuccesfull = (res: any): void => {
    this.isLoading = false;
    this.settings = res;
  };

  private listenCart() {
    this.mainDispatcher.listenerCart()
    .subscribe({
      next: this.treatLoadCartSuccesfull
    });
  }

  private treatLoadCartSuccesfull = (res: any): void => {
    this.isLoading = false;
    this.cart = res;
    this.items = (res?.produtos?.length > 0 || res?.kits?.length > 0) ?
      res.itens.map((item: any) => {
        return {
          ...item,
          ...(
            item.idSku ?
            res.produtos.find((produto: any) => produto.idSku === item.idSku) :
            res.kits.find((kit: any) => kit.idKit === item.idKit)
          )
        }
      }) : [...this.items];
    if (this.shipmentSelected) {
      this.shipment = this.shipments.find((shipment: any) => shipment.idCheckoutFrete === this.shipmentSelected);
    }
  };

  private listenShipment() {
    this.mainDispatcher.listenerShipment()
    .subscribe({
      next: this.treatLoadShipmentSuccesfull
    });
  }

  private treatLoadShipmentSuccesfull = (res: any): void => {
    if (res?.shipments) {
      this.shipments = res.shipments;
    }
    if (res?.shipmentSelected) {
      this.shipmentSelected = res.shipmentSelected;
      this.shipment = this.shipments.find((shipment: any) => shipment.idCheckoutFrete === this.shipmentSelected);
    }
  };

  private listenCoupon() {
    this.mainDispatcher.listenerCoupon()
    .subscribe({
      next: this.treatCouponSuccesfull,
    });
  }

  private treatCouponSuccesfull = (res: any): void => {
    this.couponError = null;
    if (res?.temporaryCoupon) {
      this.coupon?.reset();
      this.coupon?.setValue(res?.temporaryCoupon);
    }
    if (res?.couponApplied) {
      this.couponApplied = res.couponApplied.data;
      this.coupon?.disable();
      this.percentageDiscount = this.couponApplied.valorDescontoPercentual;
      this.realDiscount = this.couponApplied.valorDescontoReal;
    }

    if (res.error) {
      this.couponError = this.parseError(res.error, 'Erro ao aplicar cupom');
    }
    this.isLoadingCoupon = false;
  };

  private listenPayment() {
    this.mainDispatcher.listenerPayment()
    .subscribe({
      next: this.treatPaymentSuccesfull,
    });
  }

  private treatPaymentSuccesfull = (res: any): void => {
    if (res?.prePaymentSelected) {
      this.prePaymentSelected = res.prePaymentSelected;
    }
  };

  private parseError = (err: any, defaultMessage: string): string => {
    return err?.error?.errors[0] ? err?.error?.errors[0] : defaultMessage;
  };

}
