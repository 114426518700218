import { Component } from '@angular/core';
import { MainDispatcher } from 'src/app/core/dispatchers/main.dispatcher';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent {

  items: any[] = [];
  isLoading: boolean = false;

  constructor(
    private mainDispatcher: MainDispatcher
  ) {
    this.listenSettings();
  }

  private listenSettings() {
    this.mainDispatcher.listenerSettings()
    .subscribe({
      next: this.treatLoadSettingsSuccesfull
    });
  }

  private treatLoadSettingsSuccesfull = (res: any): void => {
    this.isLoading = false;
    this.items = res?.depoimentos;
  };

}
