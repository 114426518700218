import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ResponsiveService {
    destroyed = new Subject<void>();
    isSmallScreen: boolean;

    displayNameMap = new Map([
        [Breakpoints.XSmall, 'XSmall'],
        [Breakpoints.Small, 'Small'],
        [Breakpoints.Medium, 'Medium'],
        [Breakpoints.Large, 'Large'],
        [Breakpoints.XLarge, 'XLarge'],
    ]);

    constructor(breakpointObserver: BreakpointObserver) {
        this.isSmallScreen = breakpointObserver.isMatched('(max-width: 679px)');
        breakpointObserver
        .observe([
            Breakpoints.XSmall,
            Breakpoints.Small,
            Breakpoints.Medium,
            Breakpoints.Large,
            Breakpoints.XLarge,
        ])
        .pipe(takeUntil(this.destroyed))
        .subscribe(result => {
            this.isSmallScreen = breakpointObserver.isMatched('(max-width: 679px)');
        });
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }
}