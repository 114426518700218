<app-card [title]="'Detalhes do Pedido'">

  <div class="items-container multiple-items-container">
    <span *ngFor="let item of items; let i = index">
      <div class="title-area">
        <div class="img img-small " [ngStyle]="{'background': 'url('+ item?.caminhoImagem +')'}"></div>
        <div fxLayout="column wrap">
          <div class="item-name">
            {{item?.nomeProduto || item?.nomeKit}}
          </div>
          <div class="variations" *ngFor="let variation of item?.variacoes">
            {{variation?.tipoVariacao}}: {{variation?.variacao}}
          </div>
        </div>
      </div>
      <div class="price-area">
        <div>
          <span class="discount" *ngIf="item?.idKit"> {{item?.precoInicial | currency: 'BRL'}}</span>
          <span class="price" *ngIf="item?.idKit">{{item?.precoFinal | currency: 'BRL'}}</span>
          <span class="price" *ngIf="!(item?.idKit)">{{item?.precoVenda | currency: 'BRL'}}</span>
        </div>
        <div class="quantity" *ngIf="settings?.resumo?.permitirMudancaQuantidadeItem">
          <button
            mat-mini-fab
            class="mini-fab-button"
            [disabled]="isLoading"
            (click)="changeItemQuantity(item, item.quantidade + 1, i)">
            <mat-icon>add</mat-icon>
          </button>
          <span *ngIf="!isLoading">
            {{item?.quantidade}}
          </span>
          <span *ngIf="isLoading">
            <mat-progress-spinner
              matSuffix
              diameter="22"
              strokeWidt="1"
              mode="indeterminate">
            </mat-progress-spinner>
          </span>
          <button
            mat-mini-fab
            class="mini-fab-button"
            [disabled]="isLoading || item?.quantidade === 1"
            (click)="changeItemQuantity(item, item.quantidade - 1, i)">
            <mat-icon>remove</mat-icon>
          </button>
        </div>
      </div>
    </span>
  </div>

</app-card>

<app-card>

  <div class="values-area">
    <span>Itens</span>
    <span>{{itemsValue | currency: 'BRL'}}</span>
  </div>
  <div class="values-area">
    <span>Desconto</span>
    <span class="inline-toast">
      <app-in-page-toast *ngIf="discountPercentageLabel.length > 0" type="INFO">
        <span>Economize {{discountPercentageLabel}}</span>
      </app-in-page-toast>
      {{discountValue | currency: 'BRL'}}
    </span>
  </div>
  <hr>
  <div class="values-area">
    <span>SubTotal</span>
    <span>{{partialValue | currency: 'BRL'}}</span>
  </div>
  <div class="values-area">
    <span *ngIf="shippingValue > 0 && !freeShipment">Frete</span>
    <span *ngIf="shippingValue > 0 && !freeShipment">{{shippingValue | currency: 'BRL'}}</span>
    <app-in-page-toast *ngIf="freeShipment" type="SUCCESS">
      <span>Frete Grátis</span>
    </app-in-page-toast>
  </div>
  <hr>
  <div class="total-area">
    <span>Total</span>
    <span>{{totalValue | currency: 'BRL'}}</span>
  </div>

</app-card>

<app-in-page-toast *ngIf="couponError" [type]="'ERROR'" [closable]="true" (closed)="couponError = null">
  <span>{{couponError}}</span>
</app-in-page-toast>

<div class="coupon-container" *ngIf="settings?.resumo?.exibirCampoCupomDesconto && prePaymentSelected?.idFormaPagamento">
  <form [formGroup]="couponForm">
    <div fxLayout="row" fxLayoutGap="12">
      <mat-form-field appearance="outline" fxFlex="80">
        <mat-label>Insira um Cupom</mat-label>
        <input matInput required formControlName="coupon">
          <mat-error *ngIf="couponForm?.touched && coupon?.invalid">
            O valor é inválido
          </mat-error>
      </mat-form-field>
      <button
        mat-flat-button
        class="primary-button"
        fxFlex="20"
        [disabled]="isLoadingCoupon || couponForm.invalid || couponApplied"
        (click)="applyCoupon()">
        <span *ngIf="!isLoadingCoupon">Aplicar</span>
        <mat-progress-spinner
          *ngIf="isLoadingCoupon"
          diameter="25"
          strokeWidt="2"
          mode="indeterminate">
        </mat-progress-spinner>
      </button>
    </div>
  </form>

</div>

<app-testimonials></app-testimonials>


