import { Component, Input } from '@angular/core';
import { MainDispatcher } from 'src/app/core/dispatchers/main.dispatcher';

@Component({
  selector: 'app-shipment',
  templateUrl: './shipment.component.html',
  styleUrls: ['./shipment.component.scss']
})
export class ShipmentComponent {

  @Input() active: boolean = false;

  labelPosition: 'before' | 'after' = 'after';
  isLoading: boolean = false;
  errorMessage: any;

  shipments: any[] = [];
  shipmentSelected: string;
  shipmentIntegrated: string;

  constructor(
    private mainDispatcher: MainDispatcher
  ) { this.listenShipment(); }


  selectShipment() {
    this.isLoading = true;
    this.mainDispatcher.selectShipment(this.shipmentSelected);
  }

  private listenShipment() {
    this.mainDispatcher.listenerShipment()
    .subscribe({
      next: this.treatLoadShipmentSuccesfull
    });
  }

  private treatLoadShipmentSuccesfull = (res: any): void => {
    if (res?.shipmentIntegrated) this.shipmentIntegrated = this.shipmentSelected;
    if (res?.shipments?.length > 0) this.shipments = res.shipments;
    if (res?.errorMessage) this.errorMessage = res?.errorMessage;
    if (res?.success || res?.error || res?.errorMessage) this.isLoading = false;
    if (res?.shipments?.length > 0 && !this.shipmentIntegrated) this.shipmentSelected = this.shipments[0].idCheckoutFrete;
  };

}
