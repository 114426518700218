
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeContainerComponent } from './container/home-container/home-container.component';
import { AppComponent } from './app.component';

const routes: Routes = [
  {
    path: '',
    component: AppComponent,
    children: [
      {
        path: '',
        component: HomeContainerComponent
      }
    ]
  },
  {
    path: 'compra',
    loadChildren: () => import('./modules/result/result.module').then( m => m.ResultModule)
  },
  {
    path: 'pedido',
    loadChildren: () => import('./modules/result/result.module').then( m => m.ResultModule)
  },
  {
    path: 'erro',
    loadChildren: () => import('./modules/error/error.module').then( m => m.ErrorModule)
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'erro'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
