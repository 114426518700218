import { ResponsiveService } from './../../core/services/responsive/responsive.service';
import { Component } from '@angular/core';
import { MainDispatcher } from 'src/app/core/dispatchers/main.dispatcher';

@Component({
  selector: 'app-home-container',
  templateUrl: './home-container.component.html',
  styleUrls: ['./home-container.component.scss']
})
export class HomeContainerComponent {

  initialLoading: boolean = true;
  isMobileView: boolean = false;

  personalInfoStatus: 'ACTIVE' | 'INACTIVE' | 'COMPLETED' = 'ACTIVE';
  deliveryStatus: 'ACTIVE' | 'INACTIVE' | 'COMPLETED' = 'INACTIVE';
  shipmentStatus: 'ACTIVE' | 'INACTIVE' | 'COMPLETED' = 'INACTIVE';
  paymentStatus: 'ACTIVE' | 'INACTIVE' | 'COMPLETED' = 'INACTIVE';


  constructor(
    private mainDispatcher: MainDispatcher,
    private responsiveService: ResponsiveService
  ) {
    this.listenSettings();
    this.listenCart();
    this.listenPersonalInfo();
    this.listenDelivery();
    this.listenShipment();
  }

  private listenSettings() {
    this.mainDispatcher.listenerSettings()
    .subscribe({
      next: this.treatLoadSettingsSuccesfull
    });
  }

  private treatLoadSettingsSuccesfull = (res: any): void => {
  };

  private listenCart() {
    this.mainDispatcher.listenerCart()
    .subscribe({
      next: this.treatLoadCartSuccesfull
    });
  }

  private treatLoadCartSuccesfull = (res: any): void => {
    if (res?.idCarrinho) {
      this.initialLoading = false;
      this.isMobileView = this.responsiveService.isSmallScreen;
    }
  };

  private listenPersonalInfo() {
    this.mainDispatcher.listenerPersonalInfo()
    .subscribe({
      next: this.treatLoadPersonalInfoSuccesfull
    });
  }

  private treatLoadPersonalInfoSuccesfull = (res: any): void => {
    if (res?.idCliente) {
      this.personalInfoStatus = this.personalInfoStatus === 'ACTIVE' ? 'COMPLETED' : this.personalInfoStatus;
      this.deliveryStatus = this.deliveryStatus === 'INACTIVE' ? 'ACTIVE' : this.deliveryStatus;
    }
  };

  private listenDelivery() {
    this.mainDispatcher.listenerDelivery()
    .subscribe({
      next: this.treatLoadDeliverySuccesfull
    });
  }

  private treatLoadDeliverySuccesfull = (res: any): void => {
    if (res?.success) {
      this.deliveryStatus = this.deliveryStatus === 'ACTIVE' ? 'COMPLETED' : this.deliveryStatus;
      this.shipmentStatus = this.shipmentStatus === 'INACTIVE' ? 'ACTIVE' : this.shipmentStatus;
    }
  };

  private listenShipment() {
    this.mainDispatcher.listenerShipment()
    .subscribe({
      next: this.treatLoadShipmentSuccesfull
    });
  }

  private treatLoadShipmentSuccesfull = (res: any): void => {
    if (res?.success) {
      this.shipmentStatus = this.shipmentStatus === 'ACTIVE' ? 'COMPLETED' : this.shipmentStatus;
      this.paymentStatus = this.paymentStatus === 'INACTIVE' ? 'ACTIVE' : this.paymentStatus;
    }
  };

}
