<app-card
  [title]="'Envio'"
  [stepCounter]="3"
  [disabled]="!active">

  <app-in-page-toast *ngIf="!errorMessage">
    <span>Selecione um dos serviços de entrega para prosseguir com a compra.</span>
  </app-in-page-toast>
  <app-in-page-toast *ngIf="errorMessage" type="ERROR" [closable]="true" (closed)="errorMessage = null">
    <span>
      {{errorMessage}}
    </span>
  </app-in-page-toast>

  <div *ngFor="let item of shipments" class="checkbox-area">
    <div class="orientation">
      <mat-radio-group [(ngModel)]="shipmentSelected">
        <mat-radio-button [disabled]="isLoading" [value]="item.idCheckoutFrete">
          <span class="name">
            {{ item.nome }}
          </span>
          <p class="time" *ngIf="item.prazoDias > 0">{{ item.prazoDias }} Dia{{ item.prazoDias > 1 ? 's' : '' }}</p>
        </mat-radio-button>
      </mat-radio-group>
      <span class="value" *ngIf="!item.freteGratis">
        <span class="original-value">{{ item.valorFreteOriginal | currency: 'BRL' }}</span>
        {{ item.valorFrete | currency: 'BRL' }}
      </span>
      <span class="value" *ngIf="item.freteGratis">
        Grátis
      </span>
    </div>
  </div>
  <section>
    <button
        *ngIf="!(shipmentSelected && shipmentSelected === shipmentIntegrated)"
        mat-flat-button
        class="primary-button"
        [disabled]="isLoading"
        (click)="selectShipment()">
        <span *ngIf="!isLoading">Continuar</span>
        <mat-progress-spinner
          *ngIf="isLoading"
          diameter="25"
          strokeWidt="2"
          mode="indeterminate">
        </mat-progress-spinner>
      </button>
  </section>

</app-card>
