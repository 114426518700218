<app-card
  [title]="'Informações Pessoais'"
  [stepCounter]="1"
  [disabled]="!active">

  <app-in-page-toast *ngIf="!errorMessage">
    <span>
      Vamos empregar seu endereço de e-mail para: Reconhecer seu perfil, 
      registrar seu histórico de compras, 
      enviar notificações sobre pedidos e manter atualizado o seu carrinho de compras.
    </span>
  </app-in-page-toast>
  <app-in-page-toast *ngIf="errorMessage" type="ERROR" [closable]="true" (closed)="errorMessage = null">
    <span>
      {{errorMessage}}
    </span>
  </app-in-page-toast>

  <app-in-page-toast *ngIf="!isFormOpen">
    <div class="personal-data">
      <div fxFlex="85">
        <h5>{{fullName?.value}}</h5>
        <h6>{{email?.value}} | {{phone?.value | mask: phoneMask}}</h6>
        <h6>{{cpf?.value | mask: cpfMask}}</h6>
      </div>
      <div fxFlex="15">
        <button mat-icon-button class="icon-button" (click)="isFormOpen = true">
          <mat-icon>edit</mat-icon>
        </button>
      </div>
    </div>
  </app-in-page-toast>

  <form [formGroup]="personalInfoForm" *ngIf="isFormOpen">
    <div fxLayout="column">
      <mat-form-field appearance="outline" fxFlex="100">
        <mat-label>E-mail</mat-label>
        <input matInput required formControlName="email">
          <mat-error *ngIf="personalInfoForm?.touched && email?.invalid">
            O valor é inválido
          </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" fxFlex="100">
        <mat-label>Nome Completo</mat-label>
        <input matInput required formControlName="fullName">
          <mat-error *ngIf="personalInfoForm?.touched && fullName?.invalid">
            O valor é inválido
          </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" fxFlex="100">
        <mat-label>CPF</mat-label>
        <input matInput required formControlName="cpf" mask="000.000.000-00">
          <mat-error *ngIf="personalInfoForm?.touched && cpf?.invalid">
            O valor é inválido
          </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" fxFlex="100">
        <mat-label>Celular / Whatsapp</mat-label>
        <input matInput required formControlName="phone" mask="(00) 00000-0000">
          <mat-error *ngIf="personalInfoForm?.touched && phone?.invalid">
            O valor é inválido
          </mat-error>
      </mat-form-field>
    </div>
    <section>
      <button
        mat-flat-button
        class="primary-button"
        [disabled]="isLoading || personalInfoForm.invalid"
        (click)="savePersonalInfo()">
        <span *ngIf="!isLoading">Continuar</span>
        <mat-progress-spinner
          *ngIf="isLoading"
          diameter="25"
          strokeWidt="2"
          mode="indeterminate">
        </mat-progress-spinner>
      </button>
      <button
        *ngIf="isFormOpen && isEdit"
        mat-button
        class="cancel-button"
        (click)="cancel()">
        <span *ngIf="!isLoading">Cancelar</span>
        <mat-progress-spinner
          *ngIf="isLoading"
          diameter="25"
          strokeWidt="2"
          mode="indeterminate">
        </mat-progress-spinner>
      </button>
    </section>
  </form>
  </app-card>
