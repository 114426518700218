import { Component, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Observable, startWith, map } from 'rxjs';
import { MainDispatcher } from 'src/app/core/dispatchers/main.dispatcher';
import { CepService } from 'src/app/core/services/cep/cep.service';
import { _lowercaseFilter } from 'src/app/shared/utils/filters.utils';
import { statesGrouped } from 'src/app/shared/utils/states.utils';

@Component({
  selector: 'app-delivery',
  templateUrl: './delivery.component.html',
  styleUrls: ['./delivery.component.scss']
})
export class DeliveryComponent {

  @Input() active: boolean = false;

  isEdit: boolean = false;
  isFormOpen: boolean = false;
  isLoading: boolean = false;
  isLoadingCep: boolean = false;
  errorMessage: any;

  client: any;
  deliveryList: any[] = [];
  deliveryForm: FormGroup;
  deliverySelected: string;
  cartIntegrated: string;
  stateGroupOptions: Observable<any[]>;
  stateGroups: any[] = statesGrouped();

  constructor(
    private fb: FormBuilder,
    private mainDispatcher: MainDispatcher,
    private cepService: CepService
  ) { this.listenDelivery(); this.listenPersonalInfo(); }

  ngOnInit() {
    this.startForm();
    this.startStateFilter();
  }

  startForm() {
    this.deliveryForm = this.fb.group({
      cep: ['', Validators.required],
      address: ['', Validators.required],
      number: ['', Validators.required],
      district: ['', Validators.required],
      complement: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', [Validators.required, Validators.maxLength(2), Validators.minLength(2)]],
      receiver: ['', Validators.required],
      idEndereco: [null]
    });
    this.listenCep();
  }

  selectDelivery() {
    this.isLoading = true;
    this.mainDispatcher.selectDelivery(this.deliveryList.find( delivery => delivery.idEndereco === this.deliverySelected));
  }

  saveDelivery() {
    this.isEdit ?
      this.mainDispatcher.editDelivery({...this.deliveryForm.value, country: 'BR'}) :
      this.mainDispatcher.saveDelivery({...this.deliveryForm.value, country: 'BR'});
  }

  cancel() {
    this.isEdit = false;
    this.isFormOpen = false;
    this.isLoading = false;
  }

  openNew() {
    this.isEdit = false;
    this.isFormOpen = true;
    this.deliveryForm.reset();
    this.receiver?.setValue(this.client.nome);
  }

  openEdit(delivery: any) {
    this.isEdit = true;
    this.isFormOpen = true;
    this.deliveryForm.patchValue({
      cep: delivery.cep,
      address: delivery.endereco,
      number: delivery.numero,
      district: delivery.bairro,
      complement: delivery.complemento,
      city: delivery.cidade,
      state: delivery.estado,
      receiver: delivery.destinatario
    }, {emitEvent: false});
  }

  get cep(): AbstractControl | null { return this.deliveryForm.get('cep'); }
  get address(): AbstractControl | null { return this.deliveryForm.get('address'); }
  get number(): AbstractControl | null { return this.deliveryForm.get('number'); }
  get district(): AbstractControl | null { return this.deliveryForm.get('district'); }
  get complement(): AbstractControl | null { return this.deliveryForm.get('complement'); }
  get city(): AbstractControl | null { return this.deliveryForm.get('city'); }
  get state(): AbstractControl | null { return this.deliveryForm.get('state'); }
  get receiver(): AbstractControl | null { return this.deliveryForm.get('receiver'); }

  private listenDelivery() {
    this.mainDispatcher.listenerDelivery()
    .subscribe({
      next: this.treatLoadDeliverySuccesfull
    });
  }

  private treatLoadDeliverySuccesfull = (res: any): void => {
    if (res?.cartIntegrated) this.cartIntegrated = this.deliverySelected;
    if (res?.deliveryOptions?.length > 0) {
      this.deliveryList = res.deliveryOptions;
      this.deliverySelected = this.deliveryList[0].idEndereco;
    }
    if (res?.deliveryOptions?.length > 0 || res?.success || res?.error) {
      this.isEdit = false;
      this.isFormOpen = false;
      this.isLoading = false;
    }
  };

  private listenPersonalInfo() {
    this.mainDispatcher.listenerPersonalInfo()
    .subscribe({
      next: this.treatLoadPersonalInfoSuccesfull
    });
  }

  private treatLoadPersonalInfoSuccesfull = (res: any): void => {
    if (res?.idCliente) {
      this.client = res;
    }
  };

  private listenCep() {
    this.cep?.valueChanges
    .subscribe(
      val => { if(val?.length === 8) this.loadCep(val); }
    );
  }

  private loadCep(cep: string) {
    this.isLoadingCep = true;
    this.cepService.getCep(cep)
    .subscribe({
      next: this.treatLoadCepSuccesfull,
      error: this.treatLoadCepError
    });
  }

  private treatLoadCepSuccesfull = (res: any): void => {
    if (res) {
      this.deliveryForm.patchValue({
        address: res.logradouro,
        number: res.numero,
        district: res.bairro,
        complement: res.complemento,
        city: res.localidade,
        state: res.uf,
      }, { emitEvent: false });
    }
    this.isLoadingCep = false;
  };

  private treatLoadCepError = (error: any): void => {
    this.isLoadingCep = false;
    this.errorMessage = 'Erro ao carregar o CEP';
  };

  private startStateFilter = (): void => {
    this.stateGroupOptions = this.state!.valueChanges.pipe(
      startWith(''),
      map(value => this._filterGroup(value || '')),
    );
  };

  private _filterGroup(value: string): any[] {
    if (value) {
      return this.stateGroups
        .map(group => ({letter: group.letter, names: _lowercaseFilter(group.names, value)}))
        .filter(group => group.names.length > 0);
    }
    return this.stateGroups;
  }
}
