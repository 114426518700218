import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { MainDispatcher } from 'src/app/core/dispatchers/main.dispatcher';

@Component({
  selector: 'app-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.scss']
})
export class PersonalInfoComponent implements OnInit {

  @Input() active: boolean = false;

  cpfMask: any = '000.000.000-00';
  phoneMask: any = '(00) 00000-0000';

  isFormOpen: boolean = true;
  isEdit: boolean = false;
  isLoading: boolean = false;
  errorMessage: any;

  personalInfoForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private mainDispatcher: MainDispatcher
  ) { this.listenPersonalInfo();}

  ngOnInit() {
    this.startForm();
  }

  cancel() {
    this.isFormOpen = false;
    this.isEdit = false;
  }

  startForm() {
    this.personalInfoForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      fullName: ['', [Validators.required, Validators.pattern(/^(?=\S*\s\S+).*$/)]],
      cpf: ['', [Validators.required]],
      phone: ['', [Validators.required]]
    });
  }

  savePersonalInfo() {
    this.isLoading = true;
    this.isEdit ?
      this.mainDispatcher.editPersonalInfo(this.personalInfoForm.value) :
      this.mainDispatcher.savePersonalInfo(this.personalInfoForm.value);
  }

  get email(): AbstractControl | null { return this.personalInfoForm.get('email'); }
  get fullName(): AbstractControl | null { return this.personalInfoForm.get('fullName'); }
  get cpf(): AbstractControl | null { return this.personalInfoForm.get('cpf'); }
  get phone(): AbstractControl | null { return this.personalInfoForm.get('phone'); }

  private listenPersonalInfo() {
    this.mainDispatcher.listenerPersonalInfo()
    .subscribe({
      next: this.treatLoadPersonalInfoSuccesfull
    });
  }

  private treatLoadPersonalInfoSuccesfull = (res: any): void => {
    if (res?.idCliente) {
      this.isEdit = true;
      this.isFormOpen = false;
      this.isLoading = false;
    }
    if (res?.errorMessage) {
      this.errorMessage = res?.errorMessage;
      this.isLoading = false;
    }
  };
}
