<div *ngIf="visible"
    class="bg"
    [ngStyle]="{'opacity': backgroundOpacity}">
    <mat-progress-spinner
        *ngIf="!isBeehiveSpinner"
        diameter="50"
        strokeWidt="5"
        mode="indeterminate">
    </mat-progress-spinner>
    <div *ngIf="isBeehiveSpinner" class="logo-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>
