import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeContainerComponent } from './container/home-container/home-container.component';
import { SharedModule } from './shared/shared.module';
import { PersonalInfoComponent } from './pages/personal-info/personal-info.component';
import { DeliveryComponent } from './pages/delivery/delivery.component';
import { ShipmentComponent } from './pages/shipment/shipment.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { OrderDetailComponent } from './pages/order-detail/order-detail.component';
import { HttpAuthInterceptor } from './core/interceptors/http-auth.interceptor';
import { OrderBumpComponent } from './pages/order-bump/order-bump.component';
import { TestimonialsComponent } from './pages/testimonials/testimonials.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeContainerComponent,
    PersonalInfoComponent,
    DeliveryComponent,
    ShipmentComponent,
    PaymentComponent,
    OrderDetailComponent,
    OrderBumpComponent,
    TestimonialsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    SharedModule,
    FlexLayoutModule,
    NgxMaskDirective,
    NgxMaskPipe
  ],
  providers: [
    HttpAuthInterceptor,
    provideNgxMask(),
  { provide: HTTP_INTERCEPTORS, useClass: HttpAuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
