import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService } from '../base.service';
import { CartEndpointMapper } from '../../mappers/cart-endpoint.mapper';

@Injectable({
  providedIn: 'root'
})
export class CartService extends BaseService {

    constructor(
        private http: HttpClient
    ) { super(); }

    getCartSettings(payload: any): Observable<any> {
        return this.http.get(CartEndpointMapper.getBuscarConfigsIniciaisCheckout + `?${this.parseToQueryString(payload)}`);
    }

    getCart(id: any): Observable<any> {
        return this.http.get(CartEndpointMapper.getObterCarrinho + `/${id}`);
    }

    postCart(payload: any): Observable<any> {
        return this.http.post(CartEndpointMapper.postCriarCarrinhoCliente, payload);
    }

    postPersonalData(payload: any): Observable<any> {
        return this.http.post(CartEndpointMapper.postInserirDadosPessoais, payload);
    }

    putPersonalData(payload: any): Observable<any> {
        return this.http.put(CartEndpointMapper.putAtualizarDadosPessoais, payload);
    }

    getDelivery(idLoja: string, idCliente: string): Observable<any> {
        return this.http.get(CartEndpointMapper.getObterDadosEntregaCliente + `?${this.parseToQueryString({idLoja, idCliente})}`);
    }

    postDelivery(payload: any): Observable<any> {
        return this.http.post(CartEndpointMapper.postInserirDadosEntregaCliente, payload);
    }

    putDelivery(payload: any): Observable<any> {
        return this.http.put(CartEndpointMapper.putAtualizarDadosEntregaCliente, payload);
    }

    putDeliveryOnCart(payload: any): Observable<any> {
        return this.http.put(CartEndpointMapper.putAtualizarEnderecoNoCarrinho, payload);
    }

    getShipment(payload: any): Observable<any> {
        return this.http.get(CartEndpointMapper.getObterFrete + `?${this.parseToQueryString(payload)}`);
    }

    putShipment(payload: any): Observable<any> {
        return this.http.put(CartEndpointMapper.putAtualizarFrete + `/${payload.idCarrinho}`, payload);
    }

    getPayments(payload: any): Observable<any> {
        return this.http.get(CartEndpointMapper.getBuscarFormasPagamento + `?${this.parseToQueryString(payload)}`);
    }

    postPayment(payload: any): Observable<any> {
        return this.http.post(CartEndpointMapper.postRealizarPagamento, payload);
    }

    putItemQuantity(payload: any): Observable<any> {
        return this.http.put(CartEndpointMapper.putAtualizarQuantidadeItemCarrinho+ `?${this.parseToQueryString(payload)}`, {});
    }

    getOrder(payload: any): Observable<any> {
        return this.http.get(CartEndpointMapper.getBuscarPedido + `?${this.parseToQueryString(payload)}`);
    }

    applyCoupon(payload: any): Observable<any> {
        return this.http.post(CartEndpointMapper.postValidarCupom,payload);
    }

    getOrderBump(payload: any): Observable<any> {
        return this.http.get(CartEndpointMapper.getBuscarOrderBump + `?${this.parseToQueryString(payload)}`);
    }

    getUpsell(payload: any): Observable<any> {
        return this.http.get(CartEndpointMapper.getBuscarUpsell + `?${this.parseToQueryString(payload)}`);
    }

    postCartItem(payload: any): Observable<any> {
        return this.http.post(CartEndpointMapper.postAdcionarItemCarrinho + `?${this.parseToQueryString(payload)}`, {});
    }

    getBotaoCompra(idBotaoCompra: any): Observable<any> {
        return this.http.get(CartEndpointMapper.getBuscarBotaoCompra + `${idBotaoCompra}`);
    }

}
