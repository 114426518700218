import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { environment } from "src/environments/environment";

export interface CreditCardIssuerValidation {
  issuer: string;
  valid: boolean
}

export const parseCreditCard = (number: string): CreditCardIssuerValidation => {
  const cleanNumber = number ? number.replace(/\D/g, '') : '';
  const defaultResult = { issuer: "Desconhecida", valid: false };

  if (!cleanNumber || isNaN(parseInt(cleanNumber))) return defaultResult;

  const issuers: any = environment.CREDITCARDSISSUERS;

  for (const issuer in issuers) {
      if (
        issuers[issuer].includes(cleanNumber.substring(0, 2)) ||
        issuers[issuer].includes(cleanNumber.substring(0, 1))
      ) return { issuer, valid: true };
  }
  return defaultResult;
}

export function ValidatorCreditCard(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const invalid = !parseCreditCard(control.value).valid;
    return invalid ? { invalidCreditCard: { value: control.value } } : null;
  };
}