import { Injectable} from '@angular/core';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

import { CartService } from '../services/cart/cart.service';
import { ThemeService } from '../services/theme/theme.service';

@Injectable({
  providedIn: 'root'
})
export class MainDispatcher {

    private routeData: any;
    private settingsSubj: BehaviorSubject<any> = new BehaviorSubject<any>({});
    private cartSubj: BehaviorSubject<any> = new BehaviorSubject<any>({});
    private personalInfoSubj: BehaviorSubject<any> = new BehaviorSubject<any>({});
    private deliverySubj: BehaviorSubject<any> = new BehaviorSubject<any>({});
    private shipmentSubj: BehaviorSubject<any> = new BehaviorSubject<any>({});
    private paymentSubj: BehaviorSubject<any> = new BehaviorSubject<any>({});
    private orderSubj: BehaviorSubject<any> = new BehaviorSubject<any>({});
    private couponSubj: BehaviorSubject<any> = new BehaviorSubject<any>({});
    private orderBumpSubj: BehaviorSubject<any> = new BehaviorSubject<any>({});
    private upsellSubj: BehaviorSubject<any> = new BehaviorSubject<any>({});

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private cartService: CartService,
        private themeService: ThemeService
    ) { this.loadRouteData(); }

    listenerSettings(): Observable<any> { return this.settingsSubj; }

    listenerCart(): Observable<any> { return this.cartSubj; }

    listenerPersonalInfo(): Observable<any> { return this.personalInfoSubj; }

    listenerDelivery(): Observable<any> { return this.deliverySubj; }

    listenerShipment(): Observable<any> { return this.shipmentSubj; }

    listenerPayment(): Observable<any> { return this.paymentSubj; }

    listenerOrder(): Observable<any> { return this.orderSubj; }

    listenerCoupon(): Observable<any> { return this.couponSubj; }

    listenerOrderBump(): Observable<any> { return this.orderBumpSubj; }

    listenerUpsell(): Observable<any> { return this.upsellSubj; }

    savePersonalInfo(data: any) {
        this.requestSavePersonalInfo(data)
        .subscribe({ next: this.treatSavePersonalInfoSuccesfull, error: this.treatSavePersonalInfoError });
    }

    editPersonalInfo(data: any) {
        this.requestEditPersonalInfo(data)
        .subscribe({ next: this.treatEditPersonalInfoSuccesfull, error: this.treatEditPersonalInfoError });
    }

    getDelivery() {
        this.requestGetDelivery()
        .subscribe({ next: this.treatGetDeliverySuccesfull, error: this.treatGetDeliveryError });
    }

    selectDelivery(delivery: any) {
        this.deliverySubj.next({...this.deliverySubj.value, deliverySelected: delivery})
        this.requestChangeCartDelivery({idEnderecoCliente: delivery.idEndereco})
        .subscribe({ next: this.treatChangeCartDeliverySuccesfull, error: this.treatChangeCartDeliveryError });
    }

    saveDelivery(data: any) {
        this.requestSaveDelivery(data)
        .subscribe({ next: this.treatSaveDeliverySuccesfull, error: this.treatSaveDeliveryError });
    }

    editDelivery(data: any) {
        this.requestEditDelivery(data)
        .subscribe({ next: this.treatEditDeliverySuccesfull, error: this.treatEditDeliveryError });
    }

    getShipment() {
        this.requestGetShipment()
        .subscribe({ next: this.treatGetShipmentSuccesfull, error: this.treatGetShipmentError });
    }

    selectShipment(idCheckoutFrete: string) {
        this.shipmentSubj.next({
            shipmentSelected: idCheckoutFrete,
        });
        this.requestSelectShipment(idCheckoutFrete)
        .subscribe({ next: this.treatSelectShipmentSuccesfull, error: this.treatSelectShipmentError });
    }

    changePrePaymentSelected(payload: any) {
        this.paymentSubj.next({prePaymentSelected: payload});
        this.settingsSubj?.value?.cupoDescontoAutomatico?.codigo && this.paymentSubj?.value?.prePaymentSelected?.idFormaPagamento ?
            this.applyCoupon({coupon: this.settingsSubj.value.cupoDescontoAutomatico.codigo}) : this.updateCart();
    }

    selectPayment(payload: any) {
        this.requestSelectPayment(payload)
        .subscribe({ next: this.treatSelectPaymentSuccesfull, error: this.treatSelectPaymentError });
    }

    changeItemQuantity(item: any, newQuantity: number) {
        this.requestPutItemQuantity(item, newQuantity)
        .subscribe({ next: this.treatPutItemQuantitySuccesfull, error: this.treatPutItemQuantityError });
    }

    getOrder(payload: any) {
        this.requestGetOrder(payload)
        .subscribe({ next: this.treatGetOrderSuccesfull, error: this.invalidData });
    }

    applyCoupon(payload: any) {
        this.requestApplyCoupon(payload)
        .subscribe({ next: this.treatApplyCouponSuccesfull, error: this.treatApplyCouponError });
    }

    addOrderBumpToCart(payload: any) {
        this.requestPostAddOrderBumpToCart(payload)
        .subscribe({ next: this.treatAddOrderBumpToCartSuccesfull, error: this.treatAddOrderBumpToCartError });
    }

    addUpsellToCart(payload: any) {
        this.requestPostAddUpsellToCart(payload)
        .subscribe({ next: this.treatAddUpsellToCartSuccesfull, error: this.treatAddUpsellToCartError });
    }

    startNewBuy(skuId: any) {
        this.settingsSubj = new BehaviorSubject<any>({});
        this.cartSubj = new BehaviorSubject<any>({});
        this.personalInfoSubj = new BehaviorSubject<any>({});
        this.deliverySubj = new BehaviorSubject<any>({});
        this.shipmentSubj = new BehaviorSubject<any>({});
        this.paymentSubj = new BehaviorSubject<any>({});
        this.orderSubj = new BehaviorSubject<any>({});
        this.couponSubj = new BehaviorSubject<any>({});
        this.orderBumpSubj = new BehaviorSubject<any>({});
        this.upsellSubj = new BehaviorSubject<any>({});
        this.routeData = null;
        this.router.navigate([`/?pid=${skuId}`]);
        this.loadRouteData();
    }

    private loadRouteData() {
        let routeDataParams: any = this.route.snapshot.params;
        this.routeData = this.route.snapshot.queryParams;
        if (Object.keys(routeDataParams).length > 0 || Object.keys(this.routeData).length > 0) {
            this.routeData && (this.routeData.kid || this.routeData.pid) &&
            !(routeDataParams && routeDataParams.oid && routeDataParams.sid) ? this.loadSettings() :
            routeDataParams && routeDataParams.oid && routeDataParams.sid ? null : this.invalidData();
        }
    }

    private loadSettings() {
        this.requestSettings()
        .subscribe({ next: this.treatLoadSettingsSuccesfull, error: this.invalidData });
    }

    private treatLoadSettingsSuccesfull = (res: any): void => {
        this.settingsSubj.next(res.data);
        this.themeService.start(res.data);
        this.generateCart();
    };

    private generateCart() {
        this.requestGenerateCart()
        .subscribe({ next: this.treatGenerateCartSuccesfull, error: this.invalidData });
    }

    private treatGenerateCartSuccesfull = (res: any): void => {
        this.cartSubj.next(res.data);
        this.updateCart();
    };

    private updateCart() {
        this.requestGetCart()
        .subscribe({ next: this.treatGetCartSuccesfull, error: this.invalidData });
    }

    private treatGetCartSuccesfull = (res: any): void => {
        this.cartSubj.next({...res.data, loaded: true});
        this.loadOrderBump();
        this.loadUpsell();
    };

    private treatSavePersonalInfoSuccesfull = (res: any): void => {
        this.personalInfoSubj.next(res.data);
        this.getDelivery();
    };

    private treatSavePersonalInfoError = (error?: any) => {
        this.personalInfoSubj.next(this.parseError(error));
    }

    private treatEditPersonalInfoSuccesfull = (res: any): void => {
        this.personalInfoSubj.next(res.data);
    };

    private treatEditPersonalInfoError = (error?: any) => {
        this.personalInfoSubj.next(this.parseError(error));
    }

    private treatGetDeliverySuccesfull = (res: any): void => {
        this.deliverySubj.next({deliveryOptions: res});
    };

    private treatGetDeliveryError = (error?: any) => {
        this.deliverySubj.next(this.parseError(error));
    }

    private treatSaveDeliverySuccesfull = (res: any): void => {
        this.getDelivery();
    };

    private treatSaveDeliveryError = (error?: any) => {
        this.deliverySubj.next(this.parseError(error));
    }

    private treatEditDeliverySuccesfull = (res: any): void => {
        this.getDelivery();
    };

    private treatEditDeliveryError = (error?: any) => {
        this.deliverySubj.next(this.parseError(error));
    }

    private treatChangeCartDeliverySuccesfull = (res: any): void => {
        this.deliverySubj.next({
            ...this.deliverySubj.value,
            cartIntegrated: true, success: true
        });
        this.getShipment();
    };

    private treatChangeCartDeliveryError = (error?: any) => {
        this.deliverySubj.next({success: false});
    }

    private treatGetShipmentSuccesfull = (res: any): void => {
        this.shipmentSubj.next({shipments: res?.data});
    };

    private treatGetShipmentError = (error?: any) => {
        this.shipmentSubj.next(this.parseError(error));
    }

    private treatSelectShipmentSuccesfull = (res: any): void => {
        this.shipmentSubj.next({
            shipments: this.shipmentSubj?.value?.shipments,
            shipmentSelected: this.shipmentSubj?.value?.shipmentSelected,
            shipmentIntegrated: true, success: true
        });
        this.getPayments();
    };

    private treatSelectShipmentError = (error?: any) => {
        this.shipmentSubj.next({error: true});
    }

    private treatPutItemQuantitySuccesfull = (res: any): void => {
        this.updateCart();
    };

    private treatPutItemQuantityError = (error?: any) => {
        this.cartSubj.next(this.parseError(error));
    }

    private getPayments() {
        this.requestGetPayments()
        .subscribe({ next: this.treatGetPaymentsSuccesfull, error: this.treatGetPaymentsError });
    }

    private treatGetPaymentsSuccesfull = (res: any): void => {
        this.paymentSubj.next({paymentOptions: res.data, prePaymentSelected: this.paymentSubj.value.prePaymentSelected});
        this.settingsSubj?.value?.cupoDescontoAutomatico?.codigo && this.paymentSubj?.value?.prePaymentSelected?.idFormaPagamento ?
            this.applyCoupon({coupon: this.settingsSubj.value.cupoDescontoAutomatico.codigo}) : this.updateCart();
    };

    private treatGetPaymentsError = (error?: any) => {
        this.paymentSubj.next(this.parseError(error));
    }

    private treatSelectPaymentSuccesfull = (res: any): void => {
        this.paymentSubj.next({success: true, prePaymentSelected: this.paymentSubj.value.prePaymentSelected});
        this.router.navigate([`/pedido/${res.data.idPedido}/${this.settingsSubj.value.dadosLoja.idLoja}`]);
    };

    private treatSelectPaymentError = (error?: any) => {
        this.paymentSubj.next(this.parseError(error));
    }

    private treatGetOrderSuccesfull = (res: any): void => {
        this.orderSubj.next(res.data);
    };

    private treatApplyCouponSuccesfull = (res: any): void => {
        this.couponSubj.next({success: true, couponApplied: res, validCoupon: this.couponSubj.value.temporaryCoupon});
        this.updateCart();
    };

    private treatApplyCouponError = (error?: any): void => {
        this.couponSubj.next({success: false, error});
    }

    private loadOrderBump() {
        this.requestOrderBump()
        .subscribe({ next: this.treatLoadOrderBumpSuccesfull, error: this.treatLoadOrderBumpError });
    }

    private treatLoadOrderBumpSuccesfull = (res: any): void => {
        this.orderBumpSubj.next({success: true, orderBumps: res});
    };

    private treatLoadOrderBumpError = (error?: any): void => {
        this.orderBumpSubj.next(this.parseError(error));
    }

    private loadUpsell() {
        this.requestUpsell()
        .subscribe({ next: this.treatLoadUpsellSuccesfull, error: this.treatLoadUpsellError });
    }

    private treatLoadUpsellSuccesfull = (res: any): void => {
        this.upsellSubj.next({success: true, upsells: res ? res.data : []});
    };

    private treatLoadUpsellError = (error?: any): void => {
        this.upsellSubj.next(this.parseError(error));
    }

    private treatAddOrderBumpToCartSuccesfull = (res: any): void => {
        this.orderBumpSubj.next({addedToCart: this.orderBumpSubj.value.attemptToAddToCart, orderBumpAddedToCart: res.data});
        this.updateCart();
    };

    private treatAddOrderBumpToCartError = (error?: any): void => {
        this.orderBumpSubj.next(this.parseError(error));
    }

    private treatAddUpsellToCartSuccesfull = (res: any): void => {
        this.upsellSubj.next({addedToCart: this.upsellSubj.value.attemptToAddToCart, upsellAddedToCart: res.data});
        this.updateCart();
    };

    private treatAddUpsellToCartError = (error?: any): void => {
        this.upsellSubj.next(this.parseError(error));
    }

    private invalidData = (error?: any): void => {
        this.router.navigate(['/erro']);
    }

    private parseError = (httpError: any, message: string = 'Ocorreu um erro, tente mais tarde'): any => {
        return {
            errorMessage: httpError?.error?.errors && typeof httpError?.error?.errors[0] === 'string' ?
                httpError?.error?.errors[0] : message
        };
    }

    private requestSettings(): Observable<any> {
        return this.cartService.getCartSettings({
            idSkuOuKit: this.routeData.pid ? this.routeData.pid : this.routeData.kid,
            tipoCompra: this.routeData.kid ? 'Kit' : 'Produto'
        });
    }

    private requestGetCart(): Observable<any> {
        return this.cartService.getCart(this.cartSubj.value.idCarrinho);
    }

    private requestGenerateCart(): Observable<any> {
        return this.cartService.postCart({
            idLoja: this.settingsSubj.value.dadosLoja.idLoja,
            etapa: 'Criacao',
            itens: [
                {
                    idSku: this.routeData.pid ? this.routeData.pid : null,
                    quantidade: 1,
                    idOrderBump: null,
                    idUpsell: null,
                    idKit: this.routeData.kid ? this.routeData.kid : null,
                }
            ]
        });
    }

    private requestSavePersonalInfo(payload: any): Observable<any> {
        return this.cartService.postPersonalData({
            idLoja: this.settingsSubj.value.dadosLoja.idLoja,
            idCarrinho: this.cartSubj.value.idCarrinho,
            email: payload.email,
            nome: payload.fullName,
            documento: payload.cpf,
            telefone: payload.phone
        });
    }

    private requestEditPersonalInfo(payload: any): Observable<any> {
        return this.cartService.putPersonalData({
            idLoja: this.settingsSubj.value.dadosLoja.idLoja,
            idCliente: this.personalInfoSubj.value.idCliente,
            email: payload.email,
            nome: payload.fullName,
            documento: payload.cpf,
            telefone: payload.phone
        });
    }

    private requestGetDelivery(): Observable<any> {
        return this.cartService.getDelivery(
            this.settingsSubj.value.dadosLoja.idLoja,
            this.personalInfoSubj.value.idCliente
        );
    }

    private requestSaveDelivery(payload: any): Observable<any> {
        return this.cartService.postDelivery({
            idLoja: this.settingsSubj.value.dadosLoja.idLoja,
            idCarrinho: this.cartSubj.value.idCarrinho,
            idCliente: this.personalInfoSubj.value.idCliente,
            cep: payload.cep,
            endereco: payload.address,
            numero: payload.number,
            bairro: payload.district,
            complemento: payload.complement,
            cidade: payload.city,
            estado: payload.state,
            pais: payload.country,
            destinatario: payload.receiver
        });
    }

    private requestEditDelivery(payload: any): Observable<any> {
        return this.cartService.putDelivery({
            idLoja: this.settingsSubj.value.dadosLoja.idLoja,
            idEnderecoCliente: payload.idEndereco,
            cep: payload.cep,
            endereco: payload.address,
            numero: payload.number,
            bairro: payload.district,
            complemento: payload.complement,
            cidade: payload.city,
            estado: payload.state,
            pais: payload.country,
            destinatario: payload.receiver
        });
    }

    private requestChangeCartDelivery(payload: any): Observable<any> {
        return this.cartService.putDeliveryOnCart({
            idCarrinho: this.cartSubj.value.idCarrinho,
            idEnderecoCliente: payload.idEnderecoCliente,
        });
    }

    private requestGetShipment(): Observable<any> {
        return this.cartService.getShipment({
            idLoja: this.settingsSubj.value.dadosLoja.idLoja,
            idCarrinho: this.cartSubj.value.idCarrinho,
            idEstado: this.deliverySubj.value.deliverySelected.idEstado,
            cep: this.deliverySubj.value.deliverySelected.cep
        });
    }

    private requestSelectShipment(idCheckoutFrete: any): Observable<any> {
        return this.cartService.putShipment({
            idLoja: this.settingsSubj.value.dadosLoja.idLoja,
            idCarrinho: this.cartSubj.value.idCarrinho,
            idFrete: idCheckoutFrete
        });
    }

    private requestGetPayments(): Observable<any> {
        return this.cartService.getPayments({
            idLoja: this.settingsSubj.value.dadosLoja.idLoja
        });
    }

    private requestPutItemQuantity(item: any, newQuantity: number): Observable<any> {
        let payload: any = {};
        if (item.idSku) payload.idSku = item.idSku;
        if (item.idKit) payload.idKit = item.idKit;

        return this.cartService.putItemQuantity({
            ...payload,
            idCarrinho: this.cartSubj.value.idCarrinho,
            quantidade: newQuantity
        });
    }

    private requestSelectPayment(payload: any): Observable<any> {
        let items = (
            this.cartSubj.value.produtos.length > 0 ||
            this.cartSubj.value.kits.length > 0
        ) ?
            this.cartSubj.value.itens.map((item: any) => {
                return {
                    ...item,
                    ...(
                        item.idSku ?
                        this.cartSubj.value.produtos.find((produto: any) => produto.idSku === item.idSku) :
                        this.cartSubj.value.kits.find((kit: any) => kit.idKit === item.idKit)
                    )
                }
            }) : [];
        let itemsValue = items.reduce(
            (total:any, item:any) =>
              total +((
                item.idKit ? item.precoFinal : item.precoVenda
              )*item.quantidade),
            0);
        let shipment = this.cartSubj.value.fretesDisponiveis.find((shipment: any) => shipment.idCheckoutFrete === this.shipmentSubj.value.shipmentSelected);
        let freeShipment = this.couponSubj.value?.couponApplied?.data?.aplicarFreteGratis ? true : false;
        let percentageDiscount = this.couponSubj.value?.couponApplied?.data?.valorDescontoPercentual ?
            this.couponSubj.value?.couponApplied?.data?.valorDescontoPercentual : 0;
        let realDiscount = this.couponSubj.value?.couponApplied?.data?.valorDescontoReal ?
            this.couponSubj.value?.couponApplied?.data?.valorDescontoReal : 0;
        let discountValue = percentageDiscount > 0 ?
                itemsValue * (percentageDiscount/100) :
            realDiscount > 0 ?
                realDiscount : 0;
        let partialValue =  itemsValue - discountValue;
        let shippingValue =  !shipment || shipment.freteGratis || freeShipment ? 0 : shipment.valorFrete;
        let totalValue =  partialValue + shippingValue;
        let requestPayload: any = {};

        if(payload.tipoPagamento === 1){
            requestPayload.cartao = {
                numero: payload.cardNumber,
                nome: payload.holderName,
                bandeira: payload.nome,
                mesExpiracao: parseInt(payload.validDate.substring(0,2)),
                anoExpiracao: parseInt(payload.validDate.substring(2,6)),
                cvv: payload.cvv
            };
        }
        return this.cartService.postPayment({
            ...requestPayload,
            idLoja: this.settingsSubj.value.dadosLoja.idLoja,
            idCliente: this.personalInfoSubj.value.idCliente,
            idCarrinho: this.cartSubj.value.idCarrinho,
            idFormaPagamento: payload.idFormaPagamento,
            idFrete: this.shipmentSubj.value.shipmentSelected,
            parcelas: payload.installments && payload.installments > 0 ? payload.installments : 1,
            tipoCompra: this.routeData.kid ? 'Kit' : 'Produto',
            produtos: items.map(
                (item: any) => {
                    return {
                        idOrderBump: item.idOrderBump ? item.idOrderBump : null,
                        idUpsell: item.idUpsell ? item.idUpsell : null,
                        idSkuOuKit: item.idSku ? item.idSku : item.idKit,
                        quantidade: item.quantidade
                    };
                }
            ),
            valorTotal: totalValue,
            valorFrete: shippingValue,
            codigoCupom: this.couponSubj.value.validCoupon ? this.couponSubj.value.validCoupon : null
        });
    }

    private requestGetOrder(payload: any): Observable<any> {
        return this.cartService.getOrder({
            idLoja: payload.sid,
            idPedido: payload.oid
        });
    }

    private requestApplyCoupon(payload: any): Observable<any> {
        this.couponSubj.next({temporaryCoupon: payload.coupon});
        return this.cartService.applyCoupon({
            codigoCupom: payload.coupon,
            idLoja: this.settingsSubj.value.dadosLoja.idLoja,
            idCliente: this.personalInfoSubj.value.idCliente,
            idFormaPagamento: this.paymentSubj.value.prePaymentSelected.idFormaPagamento,
            produtos: this.cartSubj.value.itens
        });
    }

    private requestOrderBump(): Observable<any> {
        let items: any = (this.cartSubj?.value?.produtos?.length > 0) ?
            this.cartSubj?.value.itens.map((item: any) => {
                return {
                    ...(item.idSku ? item : {} ),
                    ...(
                        item.idSku ?
                        this.cartSubj?.value.produtos.find((produto: any) => produto.idSku === item.idSku) :
                        {}
                    )
                }
            }) : [];
        return items?.length > 0 ? this.cartService.getOrderBump({
            idLoja: this.settingsSubj.value.dadosLoja.idLoja,
            idSku: items.find((produto: any) => produto.idSku).idSku,
            valorEmCompra: items.reduce((total: any, item: any) => total + (item.precoVenda*item.quantidade), 0)
        }) : of({});
    }

    private requestUpsell(): Observable<any> {
        return this.settingsSubj.value.produto ? this.cartService.getUpsell({
            idLoja: this.settingsSubj.value.dadosLoja.idLoja,
            idSku: this.settingsSubj.value.produto.idSku
        }) : of({});
    }

    private requestPostAddOrderBumpToCart(payload: any): Observable<any> {
        let requestPayload: any = {
            idCarrinho: this.cartSubj.value.idCarrinho,
            idUpsell: null,
            idOrderBump: payload.idOrderBump,
            idSku: payload.idSku,
            quantidade: 1
        }
        this.orderBumpSubj.next({attemptToAddToCart: payload.idOrderBump});

        return this.cartService.postCartItem(requestPayload);
    }

    private requestPostAddUpsellToCart(payload: any): Observable<any> {
        let requestPayload: any = {
            idCarrinho: this.cartSubj.value.idCarrinho,
            idUpsell: payload.idUpsell,
            idOrderBump: null,
            idSku: payload.skuSelected.id,
            quantidade: 1
        }
        this.upsellSubj.next({attemptToAddToCart: payload.idUpsell});

        return this.cartService.postCartItem(requestPayload);
    }
}
