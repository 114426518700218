<app-card
  [title]="'Dados de Entrega'"
  [stepCounter]="2"
  [disabled]="!active">

  <div fxLayout="column">
    <app-in-page-toast *ngIf="!errorMessage">
      <span>Cadastre ou selecione um endereço no qual deseja receber seu produto/serviço.</span>
    </app-in-page-toast>
    <app-in-page-toast *ngIf="errorMessage" type="ERROR" [closable]="true" (closed)="errorMessage = null">
      <span>
        {{errorMessage}}
      </span>
    </app-in-page-toast>

    <div class="new-delivery" *ngIf="!isFormOpen">
      <button
        mat-button
        (click)="openNew()">
        Cadastrar novo endereço
      </button>
    </div>

    <mat-divider *ngIf="!isFormOpen"></mat-divider>

    <div class="delivery-data" *ngIf="!isFormOpen && deliveryList.length > 0">
      <app-in-page-toast class="delivery-data-container" *ngFor="let item of deliveryList">
        <div fxFlex="15">
          <mat-radio-group
            aria-labelledby="selected-delivery"
            [disabled]="isLoading"
            [(ngModel)]="deliverySelected">
            <mat-radio-button [value]="item.idEndereco"></mat-radio-button>
          </mat-radio-group>
        </div>
        <div fxFlex="70">
          <h5>{{item?.endereco}}, {{item?.numero}} - {{item?.complemento}}</h5>
          <h6>{{item?.bairro}} - {{item?.cep | mask: '00.000-00'}}</h6>
          <h6>{{item?.destinatario}}</h6>
        </div>
        <div fxFlex="15">
          <button mat-icon-button class="icon-button" (click)="openEdit(item)">
            <mat-icon>edit</mat-icon>
          </button>
        </div>
      </app-in-page-toast>
    </div>
    <div *ngIf="!isFormOpen" fxFlex="100">
      <button
          *ngIf="!(deliverySelected && deliverySelected === cartIntegrated)"
          mat-flat-button
          class="primary-button"
          [disabled]="isLoading || !deliverySelected"
          (click)="selectDelivery()">
          <span *ngIf="!isLoading">Continuar</span>
          <mat-progress-spinner
            *ngIf="isLoading"
            diameter="25"
            strokeWidt="2"
            mode="indeterminate">
          </mat-progress-spinner>
        </button>
    </div>

    <form [formGroup]="deliveryForm" *ngIf="isFormOpen">
      <div fxLayout="column">
        <div fxFlex>
          <mat-form-field appearance="outline" fxFlex="100">
            <mat-label>CEP</mat-label>
            <input matInput required formControlName="cep" mask="00.000-000">
            <mat-progress-spinner
              *ngIf="isLoadingCep"
              matSuffix
              diameter="22"
              strokeWidt="1"
              mode="indeterminate">
            </mat-progress-spinner>
            <mat-error *ngIf="deliveryForm?.touched && cep?.invalid">
              O campo é inválido
            </mat-error>
          </mat-form-field>
        </div>
        <div *ngIf="cep?.valid && !isLoadingCep" fxFlex>
          <mat-form-field appearance="outline" fxFlex="100">
            <mat-label>Endereço</mat-label>
            <input matInput required formControlName="address">
            <mat-error *ngIf="deliveryForm?.touched && address?.invalid">
              O campo é inválido
            </mat-error>
          </mat-form-field>
        </div>
        <div *ngIf="cep?.valid && !isLoadingCep" fxFlex="row wrap" fxLayoutGap="12px">
          <mat-form-field appearance="outline" fxFlex="50">
            <mat-label>Número</mat-label>
            <input matInput required formControlName="number">
            <mat-error *ngIf="deliveryForm?.touched && number?.invalid">
              O campo é inválido
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" fxFlex="50">
            <mat-label>Complemento</mat-label>
            <input matInput required formControlName="complement">
            <mat-error *ngIf="deliveryForm?.touched && complement?.invalid">
              O campo é inválido
            </mat-error>
          </mat-form-field>
        </div>
        <div *ngIf="cep?.valid && !isLoadingCep" fxFlex>
          <mat-form-field appearance="outline" fxFlex="100">
            <mat-label>Bairro</mat-label>
            <input matInput required formControlName="district">
            <mat-error *ngIf="deliveryForm?.touched && district?.invalid">
              O campo é inválido
            </mat-error>
          </mat-form-field>
        </div>
        <div *ngIf="cep?.valid && !isLoadingCep" fxFlex>
          <mat-form-field appearance="outline" fxFlex="100">
            <mat-label>Cidade</mat-label>
            <input matInput required formControlName="city">
            <mat-error *ngIf="deliveryForm?.touched && city?.invalid">
              O campo é inválido
            </mat-error>
          </mat-form-field>
        </div>
        <div *ngIf="cep?.valid && !isLoadingCep" fxFlex>
          <mat-form-field appearance="outline" fxFlex="100">
            <mat-label>Estado</mat-label>
            <input type="text"
                   matInput
                   formControlName="state"
                   required
                   [matAutocomplete]="autoGroup">
              <mat-autocomplete #autoGroup="matAutocomplete">
                <mat-optgroup *ngFor="let group of stateGroupOptions | async" [label]="group.letter">
                  <mat-option *ngFor="let stateOption of group.states" [value]="stateOption.abbreviation">
                    {{stateOption.name}}
                  </mat-option>
              </mat-optgroup>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div *ngIf="cep?.valid && !isLoadingCep" fxFlex>
          <mat-form-field appearance="outline" fxFlex="100">
            <mat-label>Destinatário</mat-label>
            <input matInput required formControlName="receiver">
          </mat-form-field>
        </div>
      </div>
      <section>
        <button
          mat-flat-button
          class="primary-button"
          [disabled]="deliveryForm.invalid"
          (click)="saveDelivery()">
          <span *ngIf="!isLoading">Salvar</span>
          <mat-progress-spinner
            *ngIf="isLoading"
            diameter="25"
            strokeWidt="2"
            mode="indeterminate">
          </mat-progress-spinner>
        </button>
        <button
          mat-button
          class="cancel-button"
          (click)="cancel()">
          <span *ngIf="!isLoading">Cancelar</span>
          <mat-progress-spinner
            *ngIf="isLoading"
            diameter="25"
            strokeWidt="2"
            mode="indeterminate">
          </mat-progress-spinner>
        </button>
      </section>
    </form>
  </div>

  </app-card>
