import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable} from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { Observable, map, of } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private USER_DATA: any;

  constructor(
    private http: HttpClient,
    private router: Router
  ) {
  }

  userId(): string {
    return this.USER_DATA?.token?.tokenUsuario?.id;
  }

  userEmail(): string {
    return this.USER_DATA?.token?.tokenUsuario?.email;
  }

  isAuthenticated(): boolean {
    return this.USER_DATA?.token?.accessToken ? true : false;
  }

}
