<app-card
    [title]="'Pagamento'"
    [stepCounter]="4"
    [orientation]="'HORIZONTAL'"
    [disabled]="!active">

  <div class="container" fxFlex="row wrap">
    <div class="payment-img-container" fxFlex="25" fxFlex.lt-lg="100">
      <app-credit-card
        *ngIf="isCreditCard"
        [cardHolder]="holderName?.value"
        [cardNumber]="cardNumber?.value"
        [goodThru]="validDate?.value"
        [cvv]="cvv?.value"
        [flipped]="flipCard">
      </app-credit-card>
      <img *ngIf="isPix" src="assets/images/pix.png" alt="pix" aria-label="image pix">
      <img *ngIf="isBoleto" src="assets/images/boleto.png" alt="boleto" aria-label="image boleto">
    </div>

    <div class="payment-method-container" fxFlex="20" fxFlex.lt-lg="100">
      <div class="payment-data" fxFlex="100">
        <h4>Escolha uma forma de pagamento</h4>
        <app-in-page-toast class="payment-data-container" *ngFor="let item of availablePaymentTypes">
          <div fxFlex="10">
            <mat-radio-group
              aria-labelledby="selected-payment"
              (ngModelChange)="paymentTypeSelectedChange($event)"
              [(ngModel)]="paymentTypeSelected">
              <mat-radio-button [value]="item.idTipoPagamento"></mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="payment-name" fxFlex="90" fxLayout="row">
            <mat-icon>{{ item.icon }}</mat-icon>
            <h5>{{item.name}}</h5>
          </div>
        </app-in-page-toast>
      </div>
    </div>

    <div *ngIf="isPix" class="payment-pix-container" fxFlex="55" fxFlex.lt-lg="100">
      <div fxLayout="column wrap">

        <app-in-page-toast>
          <span>Clique em Comprar Agora e siga as etapas para pagamento.</span>
        </app-in-page-toast>
        <app-in-page-toast>
          <span>A confirmação do pagamento por PIX é imediata.</span>
        </app-in-page-toast>
        <app-in-page-toast>
          <span>Após a confirmação de pagamento, seu pedido será processado.</span>
        </app-in-page-toast>
        <app-in-page-toast>
          <span>Clicando em comprar você concorda com nossos Termos de Uso, Privacidade e Serviços.</span>
        </app-in-page-toast>

        <app-in-page-toast *ngIf="errorMessage" type="ERROR" [closable]="true" (closed)="errorMessage = null">
          <span>
            {{errorMessage}}
          </span>
        </app-in-page-toast>
      </div>

      <div class="actions-container" fxLayout="row wrap">
        <button
          mat-flat-button
          class="primary-button"
          [disabled]="isLoading"
          (click)="selectPayment()">
          <span *ngIf="!isLoading">Comprar Agora</span>
          <mat-progress-spinner
            *ngIf="isLoading"
            diameter="25"
            strokeWidt="2"
            mode="indeterminate">
          </mat-progress-spinner>
        </button>
      </div>
    </div>

    <div *ngIf="isBoleto" class="payment-boleto-container" fxFlex="55" fxFlex.lt-lg="100">
      <div fxLayout="column wrap">

        <app-in-page-toast>
          <span>Clique em Comprar Agora e siga as etapas para pagamento.</span>
        </app-in-page-toast>
        <app-in-page-toast>
          <span>A confirmação pode levar até 3 dias úteis</span>
        </app-in-page-toast>
        <app-in-page-toast>
          <span>Após a confirmação de pagamento, seu pedido será processado.</span>
        </app-in-page-toast>
        <app-in-page-toast>
          <span>Clicando em comprar você concorda com nossos Termos de Uso, Privacidade e Serviços.</span>
        </app-in-page-toast>

        <app-in-page-toast *ngIf="errorMessage" type="ERROR" [closable]="true" (closed)="errorMessage = null">
          <span>
            {{errorMessage}}
          </span>
        </app-in-page-toast>
      </div>

      <div class="actions-container" fxLayout="row wrap">
        <button
          mat-flat-button
          class="primary-button"
          [disabled]="isLoading"
          (click)="selectPayment()">
          <span *ngIf="!isLoading">Comprar Agora</span>
          <mat-progress-spinner
            *ngIf="isLoading"
            diameter="25"
            strokeWidt="2"
            mode="indeterminate">
          </mat-progress-spinner>
        </button>
      </div>
    </div>

    <div *ngIf="isCreditCard" class="payment-creditcard-container" fxFlex="55" fxFlex.lt-lg="100">
      <form [formGroup]="paymentForm">
        <div fxLayout="row wrap">

          <mat-form-field appearance="outline" fxFlex="40" fxFlex.lt-lg="100">
            <mat-label>Número do Cartão</mat-label>
            <input matInput required formControlName="cardNumber" mask="0000 0000 0000 0000">
            <mat-error *ngIf="paymentForm?.touched && cardNumber?.invalid">
              O Número do Cartão é inválido
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex="20" fxFlex.lt-lg="100">
            <mat-label>Validade (mês/ano)</mat-label>
            <input matInput required formControlName="validDate" mask="00 / 0000">
            <mat-error *ngIf="paymentForm?.touched && validDate?.invalid">
              A Validade é inválida
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex="20" fxFlex.lt-lg="100">
            <mat-label>CVV</mat-label>
            <input matInput required formControlName="cvv" mask="000" (focus)="flipCard = true" (focusout)="flipCard = false">
            <mat-error *ngIf="paymentForm?.touched && cvv?.invalid">
              O CVV é inválido
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-lg="100">
            <mat-label>Nome do Titular</mat-label>
            <input matInput required formControlName="holderName">
            <mat-error *ngIf="paymentForm?.touched && holderName?.invalid">
              O Nome é inválido
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex="30" fxFlex.lt-lg="100" *ngIf="cardNumber?.value?.length > 4 && cardNumber?.valid">
            <mat-label>Número de Parcelas</mat-label>
            <mat-select formControlName="installments">
              <mat-option *ngFor="let installment of paymentSelected?.availableInstallments" [value]="installment">
                {{installment}}x
                <span class="interest" *ngIf="paymentSelected['jurosParcela'+ installment] > 0">{{paymentSelected['jurosParcela'+ installment]}}% (mês)</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </form>
      <div fxLayout="column wrap">
        <app-in-page-toast>
          <span>Clicando em comprar você concorda com nossos Termos de Uso, Privacidade e Serviços.</span>
        </app-in-page-toast>

        <app-in-page-toast *ngIf="errorMessage" type="ERROR" [closable]="true" (closed)="errorMessage = null">
          <span>
            {{errorMessage}}
          </span>
        </app-in-page-toast>
      </div>
      <div *ngIf="errorMessage" class="actions-container" fxLayout="row wrap">
        <app-in-page-toast type="ERROR" [closable]="true" (closed)="errorMessage = null">
          <span>
            {{errorMessage}}
          </span>
        </app-in-page-toast>
      </div>
      <div class="actions-container" fxLayout="row wrap">
        <button
          mat-flat-button
          class="primary-button"
          [disabled]="isLoading || paymentForm.invalid"
          (click)="selectPayment()">
          <span *ngIf="!isLoading">Comprar Agora</span>
          <mat-progress-spinner
            *ngIf="isLoading"
            diameter="25"
            strokeWidt="2"
            mode="indeterminate">
          </mat-progress-spinner>
        </button>
      </div>
    </div>
  </div>
</app-card>
