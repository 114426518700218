import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-in-page-toast',
  templateUrl: './in-page-toast.component.html',
  styleUrls: ['./in-page-toast.component.scss']
})
export class InPageToastComponent {

  @Input() type: 'ERROR' | 'SUCCESS' | 'INFO' = 'INFO';
  @Input() closable: boolean = false;

  @Output() closed: EventEmitter<any> = new EventEmitter<any>();

  hovered: boolean = false;

  close() {
    this.closed.emit();
  }

}
