import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';

import { MainDispatcher } from 'src/app/core/dispatchers/main.dispatcher';
import { ValidatorCreditCard, parseCreditCard } from 'src/app/shared/utils/credit-card.utils';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  @Input() active: boolean = false;

  isLoading: boolean = false;
  flipCard: boolean = false;
  errorMessage: any;

  paymentSelected: any;
  paymentList: any[];
  paymentTypeSelected: any;
  paymentTypes = [
    {
      idTipoPagamento: 1,
      name: 'Cartão de Crédito',
      icon: 'credit_card',
      available: false
    },
    {
      idTipoPagamento: 2,
      name: 'Pix',
      icon: 'account_balance',
      available: false
    },
    {
      idTipoPagamento: 3,
      name: 'Boleto Bancário',
      icon: 'receipt',
      available: false
    }
  ];

  paymentForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private mainDispatcher: MainDispatcher
  ) { this.listenPayment() }

  ngOnInit(): void {
    this.startForm();
  }

  selectPayment() {
    this.isLoading = true;
    let payload = {}
    if (this.paymentTypeSelected > 1) {
      this.paymentSelected = this.paymentList.find(payment => payment.tipoPagamento === this.paymentTypeSelected);
    }
    if (this.paymentTypeSelected === 1) {
      payload = {...this.paymentForm.value};
    }
    payload = {...payload,...this.paymentSelected};
    this.mainDispatcher.selectPayment(payload);
  }

  paymentTypeSelectedChange(event: any, cardData?: any) {
    let payload = {}
    if (event > 1) {
      this.paymentSelected = this.paymentList.find(payment => payment.tipoPagamento === event);
      payload = {...payload,...this.paymentSelected};
      this.mainDispatcher.changePrePaymentSelected(payload);
    } else if (event === 1 && this.cardNumber?.valid) {
      this.paymentSelected = cardData ? cardData : this.paymentList.find(payment => payment.tipoPagamento === event);
      if (!cardData?.availableInstallments) {
        this.paymentSelected.availableInstallments = this.paymentSelected?.parcelarAte > 0 ?
          Array.from({ length: this.paymentSelected.parcelarAte }, (el, index) => index + 1) :
          [];
      }
      payload = {...this.paymentForm.value};
      payload = {...payload,...this.paymentSelected};
      this.mainDispatcher.changePrePaymentSelected(payload);
    }
  }

  get cardNumber(): AbstractControl | null { return this.paymentForm.get('cardNumber'); }
  get validDate(): AbstractControl | null { return this.paymentForm.get('validDate'); }
  get cvv(): AbstractControl | null { return this.paymentForm.get('cvv'); }
  get holderName(): AbstractControl | null { return this.paymentForm.get('holderName'); }
  get installments(): AbstractControl | null { return this.paymentForm.get('installments'); }
  get isCreditCard(): boolean { return this.paymentTypeSelected === 1 ; }
  get isPix(): boolean { return this.paymentTypeSelected === 2 ; }
  get isBoleto(): boolean { return this.paymentTypeSelected === 3 ; }
  get availablePaymentTypes(): any[] { return this.paymentTypes.filter(el => el.available); }

  private startForm() {
    this.paymentForm = this.fb.group({
      cardNumber: [null, [Validators.required, ValidatorCreditCard()]],
      validDate: [null, Validators.required],
      cvv: [null, Validators.required],
      holderName: [null, Validators.required],
      installments: [null, Validators.required],
    });
    this.listenCreditCard();
  }

  private listenCreditCard() {
    this.cardNumber?.valueChanges
    .subscribe(val => this.validateCreditCardOptions(val));
  }

  private validateCreditCardOptions(val: any) {
    let cardOptions = parseCreditCard(val);
    if(cardOptions.valid && this.paymentTypeSelected === 1) {
      this.paymentSelected = {...this.availablePaymentTypes[0], ...this.paymentList.find(payment => payment.nome === cardOptions.issuer)};
      this.paymentSelected.availableInstallments = Array.from({ length: this.paymentSelected.parcelarAte }, (el, index) => index + 1);
      this.paymentTypeSelectedChange(1, this.paymentSelected);
    }
  }

  private listenPayment() {
    this.mainDispatcher.listenerPayment()
    .subscribe({
      next: this.treatLoadPaymentSuccesfull
    });
  }

  private treatLoadPaymentSuccesfull = (res: any): void => {
    if (res?.paymentOptions?.length > 0) {
      this.paymentList = res.paymentOptions;
      this.paymentTypes = this.paymentTypes.map(payment => {
        return {
          ...payment,
          available: this.paymentList.findIndex(el => el.tipoPagamento === payment.idTipoPagamento) > -1 ? true : false
        }
      });
      this.paymentTypeSelected = this.paymentTypes.find(payment => payment.available)?.idTipoPagamento;
      this.paymentTypeSelectedChange(this.paymentTypeSelected);
    }
    if (res?.PaymentOptions?.length > 0 || res?.success || res?.error || res?.errorMessage) {
      this.isLoading = false;
    }
    if (res?.errorMessage) this.errorMessage = res?.errorMessage;
  };

}
