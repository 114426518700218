import { Component, Input, OnInit } from '@angular/core';
import { parseCreditCard } from '../../utils/credit-card.utils';

@Component({
  selector: 'app-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.scss']
})
export class CreditCardComponent {

  @Input() cardHolder: string;
  @Input() cardNumber: string;
  @Input() goodThru: string;
  @Input() cvv: string;
  @Input() flipped: boolean = false;

  constructor() { }

  get issuerLogo(): string {
    let parsedCreditCard: any = parseCreditCard(this.cardNumber);
    let creditCardIssuerLogoName: any = parsedCreditCard?.valid ?
      parsedCreditCard?.issuer.toLowerCase().replace(/\s/g, '') : '';
    return `assets/images/issuers/${ parsedCreditCard?.valid ?
      creditCardIssuerLogoName :
      'default'}.png`;
  }

}
