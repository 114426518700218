<mat-toolbar>
    <mat-toolbar-row class="primary-toolbar">
        <div class="logo"></div>
    </mat-toolbar-row>

    <mat-toolbar-row *ngIf="secondary" class="secondary-toolbar">
        <div class="content">
            <span class="title" *ngIf="adBarText"> {{adBarText}} </span>
            <span class="featured" *ngIf="hasChronometer">
                <mat-icon>schedule</mat-icon>
                {{hours}}:{{minutes}}:{{seconds}}
            </span>
        </div>
    </mat-toolbar-row>
</mat-toolbar>
