import { Component, Input } from '@angular/core';
import { MainDispatcher } from 'src/app/core/dispatchers/main.dispatcher';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent {

  @Input() secondary: boolean = true;

  adBarText: any;
  timer: any;
  hasChronometer: boolean = false;
  chronometerEndTime: any;
  hours: any;
  minutes: any;
  seconds: any;

  constructor(
    private mainDispatcher: MainDispatcher
  ) {
    this.listenSettings();
  }

  private listenSettings() {
    this.mainDispatcher.listenerSettings()
    .subscribe({
      next: this.treatLoadSettingsSuccesfull
    });
  }

  private treatLoadSettingsSuccesfull = (res: any): void => {
    this.adBarText = res?.cabecalho?.textoBarraAnuncio;
    if (res?.cabecalho?.tempoCronometro > 0) {
      this.hasChronometer = true;
      this.chronometerEndTime = new Date().getTime() + (res.cabecalho.tempoCronometro*1000*60);
      this.timer = setInterval(this.updateChronometer, 1000);
    }
  };

  private updateChronometer = (): void => {
    let timeRemaining: any = parseInt(((this.chronometerEndTime - new Date().getTime()) / 1000)+'')
    if ( timeRemaining >= 0 ) {
      this.hours   = parseInt((timeRemaining / 3600)+'');
      timeRemaining   = (timeRemaining % 3600);
      this.minutes = parseInt((timeRemaining / 60)+'');
      timeRemaining   = (timeRemaining % 60);
      this.seconds = parseInt(timeRemaining);
    } else {
      clearInterval(this.timer);
    }
  }

}
