<app-full-page-loading [visible]="initialLoading"></app-full-page-loading>
<app-toolbar></app-toolbar>

<div class="pages-container" *ngIf="!initialLoading">
    <app-order-detail *ngIf="isMobileView"></app-order-detail>
    <app-upsell></app-upsell>
    <app-personal-info [active]="['ACTIVE', 'COMPLETED'].includes(personalInfoStatus)"></app-personal-info>
    <app-delivery [active]="['ACTIVE', 'COMPLETED'].includes(deliveryStatus)"></app-delivery>
    <app-shipment [active]="['ACTIVE', 'COMPLETED'].includes(shipmentStatus)"></app-shipment>
    <app-order-detail *ngIf="!isMobileView"></app-order-detail>
    <app-order-bump></app-order-bump>
    <app-payment [active]="['ACTIVE', 'COMPLETED'].includes(paymentStatus)"></app-payment>
</div>