<div class="card">
  <div class="card-inner" [ngClass]="{'show-back-card': flipped}">
      <div class="front">
          <div class="vector-img"></div>
          <div class="row">
              <img src="assets/images/chip.png" width="30px">
              <img [src]="issuerLogo" width="60px">
          </div>
          <div class="row card-no">
              <p>{{cardNumber | mask: '0000  0000  0000  0000'}}</p>
          </div>
          <div class="row card-holder">
              <p></p>
              <p>VALIDADE</p>
          </div>
          <div class="row name">
              <p>{{cardHolder}}</p>
              <p>{{goodThru | mask: '00 / 0000'}}</p>
          </div>
      </div>
      <div class="back">
          <div class="vector-img"></div>
          <div class="bar"></div>
          <div class="row card-cvv">
              <div>
                  <img src="assets/images/pattern.png">
              </div>
              <p>{{cvv}}</p>
          </div>
          <div class="row signature">
              <img [src]="issuerLogo" width="80px">
          </div>
      </div>
  </div>
</div>