export const _lowercaseFilter = (opt: string[], value: string): string[] => {
    const filterValue = value.toLowerCase();
    return opt.filter(item => item.toLowerCase().includes(filterValue));
};
[
    {
      letter: 'A',
      states: [
        { name: 'Acre', abbreviation: 'AC' },
        { name: 'Alagoas', abbreviation: 'AL' },
        { name: 'Amapá', abbreviation: 'AP' },
        { name: 'Amazonas', abbreviation: 'AM' }
      ],
    },
    {
      letter: 'B',
      states: [
        { name: 'Bahia', abbreviation: 'BA' }
      ],
    },
    {
      letter: 'C',
      states: [
        { name: 'Ceará', abbreviation: 'CE' }
      ],
    },
    {
      letter: 'D',
      states: [
        { name: 'Distrito Federal', abbreviation: 'DF' }
      ],
    },
    {
      letter: 'E',
      states: [
        { name: 'Espírito Santo', abbreviation: 'ES' }
      ],
    },
    {
      letter: 'G',
      states: [
        { name: 'Goiás', abbreviation: 'GO' }
      ],
    },
    {
      letter: 'M',
      states: [
        { name: 'Maranhão', abbreviation: 'MA' },
        { name: 'Mato Grosso', abbreviation: 'MT' },
        { name: 'Mato Grosso do Sul', abbreviation: 'MS' },
        { name: 'Minas Gerais', abbreviation: 'MG' }
      ],
    },
    {
      letter: 'P',
      states: [
        { name: 'Pará', abbreviation: 'PA' },
        { name: 'Paraíba', abbreviation: 'PB' },
        { name: 'Paraná', abbreviation: 'PR' },
        { name: 'Pernambuco', abbreviation: 'PE' },
        { name: 'Piauí', abbreviation: 'PI' }
      ],
    },
    {
      letter: 'R',
      states: [
        { name: 'Rio de Janeiro', abbreviation: 'RJ' },
        { name: 'Rio Grande do Norte', abbreviation: 'RN' },
        { name: 'Rio Grande do Sul', abbreviation: 'RS' },
        { name: 'Rondônia', abbreviation: 'RO' },
        { name: 'Roraima', abbreviation: 'RR' }
      ],
    },
    {
      letter: 'S',
      states: [
        { name: 'Santa Catarina', abbreviation: 'SC' },
        { name: 'São Paulo', abbreviation: 'SP' },
        { name: 'Sergipe', abbreviation: 'SE' }
      ],
    },
    {
      letter: 'T',
      states: [
        { name: 'Tocantins', abbreviation: 'TO' }
      ],
    }
  ]