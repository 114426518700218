import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent {

  @Input() title: string = '';
  @Input() disabled: boolean = false;
  @Input() hasHeader: boolean = true;
  @Input() limitHeight: boolean = true;
  @Input() stepCounter: number;
  @Input() orientation: 'VERTICAL' | 'HORIZONTAL' | 'FLEX' = 'VERTICAL';

}
