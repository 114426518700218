import { Component } from '@angular/core';
import { MainDispatcher } from 'src/app/core/dispatchers/main.dispatcher';

@Component({
  selector: 'app-order-bump',
  templateUrl: './order-bump.component.html',
  styleUrls: ['./order-bump.component.scss']
})
export class OrderBumpComponent {

  cart: any;
  settings: any;
  errorMessage: any;
  prePaymentSelected: any;
  orderBumps: any[] = [];
  orderBumpsAddedToCart: any[] = [];

  isLoadingItem: string;

  constructor(
    private mainDispatcher: MainDispatcher
  ) {
    this.listenSettings();
    this.listenPayment();
    this.listenOrderBump();
  }

  addToCart(item: any) {
    this.isLoadingItem = item.idOrderBump;
    this.mainDispatcher.addOrderBumpToCart(item);
  }

  private listenSettings() {
    this.mainDispatcher.listenerSettings()
    .subscribe({
      next: this.treatLoadSettingsSuccesfull
    });
  }

  private treatLoadSettingsSuccesfull = (res: any): void => {
    this.settings = res;
  };

  private listenPayment() {
    this.mainDispatcher.listenerPayment()
    .subscribe({
      next: this.treatPaymentSuccesfull,
    });
  }

  private treatPaymentSuccesfull = (res: any): void => {
    if (res?.prePaymentSelected) {
      this.prePaymentSelected = res.prePaymentSelected;
    }
  };

  private listenOrderBump() {
    this.mainDispatcher.listenerOrderBump()
    .subscribe({
      next: this.treatOrderBumpSuccesfull,
    });
  }

  private treatOrderBumpSuccesfull = (res: any): void => {
    if (res?.orderBumps) {
      this.orderBumps = res.orderBumps;
    }
    if (res?.addedToCart) {
      this.isLoadingItem = '';
      this.orderBumpsAddedToCart = [...this.orderBumpsAddedToCart, res.addedToCart];
    }
    if (res?.errorMessage) {
      this.errorMessage = res?.errorMessage;
      this.isLoadingItem = '';
    }
  };

}
