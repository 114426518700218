<app-card *ngIf="items?.length && items.length > 0">

  <div class="items-container multiple-items-container">
    <span *ngFor="let item of items; let i = index">
      <div class="title-area">
        <div class="img img-small " [ngStyle]="{
          'background': 'url('+
          (item?.caminhoLogicoFoto || '/assets/images/nogender.jpg')
          +')'}">
        </div>
        <div fxLayout="column wrap">
          <div fxLayout="row" class="stars-container">
            <mat-icon class="star-off" [ngClass]="{'star-on': item?.quantidadeEstrelas >= 1}">{{item?.quantidadeEstrelas >= 1 ? 'star' : 'star_outline'}}</mat-icon>
            <mat-icon class="star-off" [ngClass]="{'star-on': item?.quantidadeEstrelas >= 2}">{{item?.quantidadeEstrelas >= 2 ? 'star' : 'star_outline'}}</mat-icon>
            <mat-icon class="star-off" [ngClass]="{'star-on': item?.quantidadeEstrelas >= 3}">{{item?.quantidadeEstrelas >= 3 ? 'star' : 'star_outline'}}</mat-icon>
            <mat-icon class="star-off" [ngClass]="{'star-on': item?.quantidadeEstrelas >= 4}">{{item?.quantidadeEstrelas >= 4 ? 'star' : 'star_outline'}}</mat-icon>
            <mat-icon class="star-off" [ngClass]="{'star-on': item?.quantidadeEstrelas >= 5}">{{item?.quantidadeEstrelas >= 5 ? 'star' : 'star_outline'}}</mat-icon>
          </div>
          <div class="item-name">
            {{item?.nomePessoa}}
          </div>
          <div class="variations">
            {{item?.depoimento}}
          </div>
        </div>
      </div>
    </span>
  </div>

</app-card>



