export class ThemeVariables {

    [x: string]: any;

    constructor(data: any) {
        this['corBotaoPrimario'] = [
            {variable: '--bee-color-primary-button', value: data['corBotaoPrimario']}
        ];
        this['corBotaoSecundario'] = [
            {variable: '--bee-color-secondary-button', value: data['corBotaoSecundario']}
        ];
        this['corBotaoTextoTerciario'] = [
            {variable: '--bee-color-terciary-button-text', value: data['corBotaoTextoTerciario']}
        ];
        this['corDescricao'] = [
            {variable: '--bee-color-description', value: data['corDescricao']}
        ];
        this['corEtapaCompraAtiva'] = [
            {variable: '--bee-color-active-buy', value: data['corEtapaCompraAtiva']}
        ];
        this['corNumeroTagEtapas'] = [
            {variable: '--bee-color-number-stages', value: data['corNumeroTagEtapas']}
        ];
        this['corTagDesconto'] = [
            {variable: '--bee-color-tag-discount', value: data['corTagDesconto']}
        ];
        this['corTagEtapas'] = [
            {variable: '--bee-color-tag-stages', value: data['corTagEtapas']}
        ];
        this['corTextoBotaoPrimario'] = [
            {variable: '--bee-color-primary-button-text', value: data['corTextoBotaoPrimario']}
        ];
        this['corTextoBotaoSecundario'] = [
            {variable: '--bee-color-secondary-button-text', value: data['corTextoBotaoSecundario']}
        ];
        this['corTextoTagDesconto'] = [
            {variable: '--bee-color-tag-discount-text', value: data['corTextoTagDesconto']}
        ];
        this['corTitulos'] = [
            {variable: '--bee-color-title', value: data['corTitulos']}
        ];
        this['corValorTotal'] = [
            {variable: '--bee-color-total', value: data['corValorTotal']}
        ];
        this['corBarraCronometro'] = [
            {variable: '--bee-color-secondary', value: data['corBarraCronometro']},
            {variable: '--bee-color-secondary-toolbar', value: data['corBarraCronometro']}
        ];
        this['corCabecalho'] = [
            {variable: '--bee-color-primary', value: data['corCabecalho']},
            {variable: '--bee-color-primary-toolbar', value: data['corCabecalho']}
        ];
        this['corCronometro'] = [
            {variable: '--bee-color-timer', value: data['corCronometro']},
            {variable: '--bee-color-progress-bar', value: data['corCronometro']}
        ];
        this['corElementosCabecalho'] = [
            {variable: '--bee-color-timer-icon', value: data['corElementosCabecalho']}
        ];
        this['corTextoCronometro'] = [
            {variable: '--bee-color-timer-text', value: data['corTextoCronometro']},
            {variable: '--bee-color-progress-bar-number', value: data['corTextoCronometro']}
        ];
        this['botoesLetrasCaixaAlta'] = [
            {variable: '--bee-uppercase-button-text', value: data['botoesLetrasCaixaAlta'] ? 'uppercase' : 'capitalize'}
        ];
        this['formatoVisualEBotoes'] = [
            {variable: '--bee-borderradius-button-text', value: data['formatoVisualEBotoes'] + 'px'}
        ];
        this['logotipo'] = [
            {variable: '--bee-logo', value: `url(${data['logotipo']}) no-repeat` }
        ];
    }
}