<app-card
  *ngIf="upsells && upsells.length > 0"
  [title]="'Recomendado p/ Você'"
  [orientation]="'HORIZONTAL'"
  [limitHeight]="false">

  <app-in-page-toast *ngIf="errorMessage" type="ERROR" [closable]="true" (closed)="errorMessage = null">
    <span>
      {{errorMessage}}
    </span>
  </app-in-page-toast>
  <div class="items-container multiple-items-container">
    <div class="upsell-container" *ngFor="let item of upsells; let i = index">
      <div class="img-area">
        <div class="img img-small " [ngStyle]="{
          'background': 'url('+(item?.skuSelected?.skuImagem[0]?.url || item?.caminhoImagem) +')'}">
        </div>
      </div>
      <div class="title-area">
        <span>{{item?.produto?.nome}}</span>
      </div>
      <div class="price-area">
        <div>
          <span class="price">{{
            ( item?.skuSelected?.precoVenda || item?.precoUnitario ) | currency: 'BRL'}}
          </span>
        </div>
      </div>
      <div class="skus-area" *ngIf="item?.availableVariations?.length > 0 && item?.produto?.skus?.length > 1">
        <div *ngFor="let variation of item?.availableVariations">
            <mat-form-field>
                <mat-label>{{variation?.title}}</mat-label>
                <mat-select [(ngModel)]="item['selected'+variation?.title]" (ngModelChange)="changeVariation($event, variation.title, item.id)">
                  <mat-option *ngFor="let option of variation?.options" [value]="option">{{option}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
      </div>
      <div class="add-to-cart">
        <button
          mat-flat-button
          class="primary-button"
          [disabled]="isLoadingItem === item.idUpsell || !(item?.skuSelected) || upsellsAddedToCart.includes(item.idUpsell)"
          (click)="validAt === 'No Checkout' ? addToCart(item) : redirectToNewCheckout(item)">
          <span *ngIf="!(isLoadingItem === item.idUpsell)">{{validAt === 'No Checkout' ? 'Adicionar' : 'Comprar'}} </span>
          <mat-progress-spinner
            *ngIf="isLoadingItem === item.idUpsell"
            diameter="25"
            strokeWidt="2"
            mode="indeterminate">
          </mat-progress-spinner>
        </button>
      </div>
    </div>
  </div>

</app-card>
