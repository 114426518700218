export const environment = {
  PRODUCTION: true,

  APIS: {
    MAIN: 'https://checkout.gateway.sourcefile.com.br/paysec/api',
  },

  CREDITCARDSISSUERS: {
    // Support 1 or 2 characters, and put image at assets/images/issuers
    'American Express': ['34','37'],
    'Mastercard': ['51','52','53','54','55'],
    'Dinners': ['30','36','38','39'],
    'Discover': ['60','65'],
    'Visa': ['4'],
    'Elo': ['63']
  }
};
