<mat-card
    class="bee-card"
    [ngClass]="{
        'disabled-card': disabled,
        'vertical-card': orientation === 'VERTICAL',
        'horizontal-card': orientation === 'HORIZONTAL',
        'horizontal-card-limited': limitHeight,
        'flex-card': orientation === 'FLEX',
    }">
    <mat-card-header *ngIf="title">
        <div class="card-header">
            <div *ngIf="stepCounter" class="badge">{{stepCounter}}</div>
            <mat-card-title>{{title}}</mat-card-title>
        </div>
    </mat-card-header>
    <mat-card-content *ngIf="!disabled">
        <ng-content></ng-content>
    </mat-card-content>
</mat-card>