import { environment } from "src/environments/environment";

export class CartEndpointMapper {
    // CheckoutCarrinho
    // GET - ObterCarrinho
    static getObterCarrinho: string = `${environment.APIS.MAIN}/checkoutcarrinho`
    // POST - AdcionarItemCarrinho
    static postAdcionarItemCarrinho: string = `${environment.APIS.MAIN}/checkoutcarrinho/item`
    // DELETE - RemoverItemCarrinho
    static deleteRemoverItemCarrinho: string = `${environment.APIS.MAIN}/checkoutcarrinho/item`
    // PUT - AtualizarQuantidadeItemCarrinho
    static putAtualizarQuantidadeItemCarrinho: string = `${environment.APIS.MAIN}/checkoutcarrinho/item`
    // POST - CriarCarrinhoCliente
    static postCriarCarrinhoCliente: string = `${environment.APIS.MAIN}/checkoutcarrinho`

    // CheckoutConfiguracao
    // GET - BuscarConfigsIniciaisCheckout
    static getBuscarConfigsIniciaisCheckout: string = `${environment.APIS.MAIN}/checkoutconfiguracao/configuracoes`
    // GET - BuscarOrderBump
    static getBuscarOrderBump: string = `${environment.APIS.MAIN}/checkoutconfiguracao/orderbump`
    // GET - BuscarUpsell
    static getBuscarUpsell: string = `${environment.APIS.MAIN}/checkoutconfiguracao/upsell`
    // GET - Retorna o botão de compra com base no IdBotaoCompra /{idBotaoCompra}
    static getBuscarBotaoCompra: string = `${environment.APIS.MAIN}/checkoutconfiguracao/botao-compra/`

    // CheckoutDadosEntrega
    // GET - ObterDadosEntregaCliente
    static getObterDadosEntregaCliente: string = `${environment.APIS.MAIN}/checkoutdadosentrega`
    // POST - InserirDadosEntregaCliente
    static postInserirDadosEntregaCliente: string = `${environment.APIS.MAIN}/checkoutdadosentrega`
    // PUT - AtualizarDadosEntregaCliente
    static putAtualizarDadosEntregaCliente: string = `${environment.APIS.MAIN}/checkoutdadosentrega`
    // PUT - AtualizarEnderecoNoCarrinho
    static putAtualizarEnderecoNoCarrinho: string = `${environment.APIS.MAIN}/checkoutdadosentrega/endereco/carrinho`

    // CheckoutDadosPessoais
    // GET - ObterDadosPessoais
    static getObterDadosPessoais: string = `${environment.APIS.MAIN}/checkoutdadospessoais`
    // POST - InserirDadosPessoais
    static postInserirDadosPessoais: string = `${environment.APIS.MAIN}/checkoutdadospessoais`
    // PUT - AtualizarDadosPessoais
    static putAtualizarDadosPessoais: string = `${environment.APIS.MAIN}/checkoutdadospessoais`

    // CheckoutFrete
    // GET - ObterFrete
    static getObterFrete: string = `${environment.APIS.MAIN}/checkoutfrete`
    // PUT - AtualizarFrete
    static putAtualizarFrete: string = `${environment.APIS.MAIN}/checkoutfrete`

    // CheckoutPagamento
    // POST - ValidarCupom
    static postValidarCupom: string = `${environment.APIS.MAIN}/checkoutpagamento/cupom`
    // GET - BuscarFormasPagamento
    static getBuscarFormasPagamento: string = `${environment.APIS.MAIN}/checkoutpagamento/formas-pagamento`
    // POST - RealizarPagamento
    static postRealizarPagamento: string = `${environment.APIS.MAIN}/checkoutpagamento`
    // GET - BuscarPedido
    static getBuscarPedido: string = `${environment.APIS.MAIN}/checkoutpagamento/pedido`

}
