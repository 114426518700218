import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ThemeVariables } from '../../models/theme-variables';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  private themeData: any;
  private pageBody: any = document.querySelector('html');

  constructor(
    @Inject(DOCUMENT) private _document: any,
    private title: Title
  ) { }

  start(payload: any): any {
    this.themeData = new ThemeVariables({...payload.aparencia, ...payload.cabecalho});

    if (payload?.dadosLoja?.nome) this.setPageTitle(payload.dadosLoja.nome);
    if (payload?.dadosLoja?.urlFavIcon) this.setFavicons(payload.dadosLoja.urlFavIcon);
    this.changeTheme();
  }

  private changeTheme() {
    Object.entries(this.themeData).map((entry: [string, any]) => {
      if (entry[1] && entry[1].length > 0) {
        entry[1].map((item: any) => {
          if (item !== null) this.pageBody.style.setProperty(item.variable, item.value);
        });
      }
    });
  }

  private setPageTitle(newTitle: string): void {
    this.title.setTitle(newTitle);
  }

  private setFavicons(uri: any): void {
    this._document.getElementById('favicon').setAttribute('href', uri);
  }

}
