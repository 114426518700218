import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-full-page-loading',
  templateUrl: './full-page-loading.component.html',
  styleUrls: ['./full-page-loading.component.scss']
})
export class FullPageLoadingComponent {

  @Input() visible: boolean = false;
  @Input() backgroundOpacity: number = 1;

  isBeehiveSpinner: boolean = false;

  constructor() {
    this.isBeehiveSpinner = window.location.host.toLowerCase().includes('beehive');
  }

}
