<app-card
  *ngIf="orderBumps && orderBumps.length > 0"
  [title]="'Recomendado p/ Você'"
  [orientation]="'HORIZONTAL'">

  <app-in-page-toast *ngIf="errorMessage" type="ERROR" [closable]="true" (closed)="errorMessage = null">
    <span>
      {{errorMessage}}
    </span>
  </app-in-page-toast>
  <div class="items-container multiple-items-container">
    <div class="orderbump-container" *ngFor="let item of orderBumps; let i = index">
      <div class="img-area">
        <div class="img img-small " [ngStyle]="{'background': 'url('+ item?.caminhoImagem +')'}"></div>
      </div>
      <div class="title-area">
        <span>{{item?.nomeProduto}}</span>
        <span class="description">{{item?.mensagemOrderBump}}</span>
      </div>
      <div class="price-area">
        <div>
          <span class="discount"> {{item?.precoVenda | currency: 'BRL'}}</span>
          <span class="price">{{item?.precoPromocional | currency: 'BRL'}}</span>
        </div>
      </div>
      <div class="add-to-cart">
        <button
          *ngIf="!(item?.textoBotao)"
          mat-mini-fab
          class="mini-fab-button"
          [disabled]="isLoadingItem === item.idOrderBump || orderBumpsAddedToCart.includes(item.idOrderBump)"
          (click)="addToCart(item)">
          <span></span>
          <mat-icon>shopping_cart</mat-icon>
          <span *ngIf="isLoadingItem === item.idOrderBump">
            <mat-progress-spinner
              matSuffix
              diameter="22"
              strokeWidt="1"
              mode="indeterminate">
            </mat-progress-spinner>
          </span>
        </button>
        <button
          *ngIf="item?.textoBotao"
          mat-flat-button
          class="primary-button"
          [disabled]="isLoadingItem === item.idOrderBump || orderBumpsAddedToCart.includes(item.idOrderBump)"
          (click)="addToCart(item)">
          <span *ngIf="!(isLoadingItem === item.idOrderBump)">{{item?.textoBotao}}</span>
          <mat-progress-spinner
            *ngIf="isLoadingItem === item.idOrderBump"
            diameter="25"
            strokeWidt="2"
            mode="indeterminate">
          </mat-progress-spinner>
        </button>
      </div>
    </div>
  </div>

</app-card>
